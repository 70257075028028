import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/mop/modal/ResetFormModal.module.scss';

const ResetFormModal = ({ handleClose, handleReset }) => {
    const { enqueueSnackbar } = useSnackbar();

    const _handleReset = () => {
        enqueueSnackbar({
            variant: 'success',
            message: 'Form Reset',
            autoHideDuration: 3000
        });
        handleReset();
    };

    return (
        <Modal open dismissable handleClose={handleClose}>
            <Modal.Title icon={faUndo}>Reset MOP</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to reset the MOP?
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Confirm" onClick={_handleReset} />
            </Modal.Actions>
        </Modal>
    );
};

export default ResetFormModal;
