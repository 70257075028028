import { useCallback, useState } from 'react';

const useSessionStorage = (key, defaultValue) => {

    const [state, setState] = useState(JSON.parse(sessionStorage.getItem(key)));

    const updateValue = useCallback(
        (value) => {
            setState(value);
            if (!value) {
                sessionStorage.clear(key);
            } else {
                sessionStorage.setItem(
                    key,
                    typeof value === 'string' ? value : JSON.stringify(value)
                );
            }
        },
        [key]
    );

    return [state ?? defaultValue, updateValue];
};

export default useSessionStorage;
