import { Fragment, useEffect, useRef } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useToggle from '../../../hooks/useToggle';

import { TextTooltip } from '../Tooltip';
import ColorPicker from './ColorPicker';
import {
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faBold,
    faFillDrip,
    faHeader,
    faHighlighter,
    faItalic,
    faListOl,
    faListUl,
    faParagraph,
    faRedo,
    faStrikethrough,
    faTrashCan,
    faUnderline,
    faUndo,
    faWindowMinimize
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import styles from '../../../styles/general/input/RichTextInput.module.scss';
import { isNullOrEmpty } from '../../../js/services/validation';

const RichTextInput = ({
    value,
    onChange,
    placeholder,
    readonly,
    lines
}) => {
    const originalValue = useRef();

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: {
                    HTMLAttributes: {
                        class: styles.heading
                    }
                }
                /* blockquote: {
                    HTMLAttributes: {
                        class: styles.blockQuote
                    }
                } */
            }),
            Color,
            TextAlign.configure({
                types: ['heading', 'paragraph']
            }),
            Underline,
            Highlight,
            TextStyle,
            Placeholder.configure({
                placeholder: placeholder
            }),
            /* Focus.configure({
        className: styles.hasFocus
    }), */
            Typography
        ],
        value,
        onUpdate: (val) => onChange?.(val?.editor?.getHTML()),
        editorProps: {
            attributes: {
                class: styles.editor
            }
        },
        content: value,
        editable: !readonly
    });

    const leftRibbonItems = [
        {
            icon: faHeader,
            title: 'Heading',
            action: () =>
                editor.chain().focus().toggleHeading({ level: 3 }).run(),
            isActive: () => editor.isActive('heading', { level: 3 })
        },
        {
            icon: faParagraph,
            title: 'Paragraph',
            action: () => editor.chain().focus().setParagraph().run(),
            isActive: () => editor.isActive('paragraph')
        },
        {
            type: 'divider'
        },
        {
            icon: faBold,
            title: 'Bold',
            action: () => editor.chain().focus().toggleBold().run(),
            isActive: () => editor.isActive('bold')
        },
        {
            icon: faItalic,
            title: 'Italic',
            action: () => editor.chain().focus().toggleItalic().run(),
            isActive: () => editor.isActive('italic')
        },
        {
            icon: faStrikethrough,
            title: 'Strikethrough',
            action: () => editor.chain().focus().toggleStrike().run(),
            isActive: () => editor.isActive('strike')
        },
        {
            icon: faUnderline,
            title: 'Underline',
            action: () => editor.chain().focus().toggleUnderline().run(),
            isActive: () => editor.isActive('underline')
        },
        {
            icon: faFillDrip,
            type: 'menu',
            title: 'Text Color',
            MenuRender: ColorPicker,
            menuProps: {
                handleChange: (color) =>
                    editor.chain().focus().setColor(color).run()
            }
        },
        {
            icon: faHighlighter,
            title: 'Highlight',
            action: () => editor.chain().focus().toggleHighlight().run(),
            isActive: () => editor.isActive('highlight')
        },
        {
            type: 'divider'
        },
        {
            icon: faAlignLeft,
            title: 'Left Align',
            action: () => editor.chain().focus().setTextAlign('left').run(),
            isActive: () => editor.isActive({ textAlign: 'left' })
        },
        {
            icon: faAlignCenter,
            title: 'Center Align',
            action: () => editor.chain().focus().setTextAlign('center').run(),
            isActive: () => editor.isActive({ textAlign: 'center' })
        },
        {
            icon: faAlignRight,
            title: 'Right Align',
            action: () => editor.chain().focus().setTextAlign('right').run(),
            isActive: () => editor.isActive({ textAlign: 'right' })
        },
        {
            type: 'divider'
        },
        {
            icon: faListUl,
            title: 'Bullet List',
            action: () => editor.chain().focus().toggleBulletList().run(),
            isActive: () => editor.isActive('bulletList')
        },
        {
            icon: faListOl,
            title: 'Ordered List',
            action: () => editor.chain().focus().toggleOrderedList().run(),
            isActive: () => editor.isActive('orderedList')
        },
        /*  {
            icon: faListCheck,
            title: 'Task List',
            action: () => editor.chain().focus().toggleTaskList().run(),
            isActive: () => editor.isActive('taskList')
        },
        {
            type: 'divider'
        },
        {
            icon: faQuoteRight,
            title: 'Blockquote',
            action: () => editor.chain().focus().toggleBlockquote().run(),
            isActive: () => editor.isActive('blockquote')
        }, */
        {
            icon: faWindowMinimize,
            title: 'Horizontal Rule',
            action: () => editor.chain().focus().setHorizontalRule().run()
        },
        {
            type: 'divider'
        }
    ];

    const rightRibbonItems = [
        {
            icon: faTrashCan,
            title: 'Clear Formatting',
            action: () =>
                editor.chain().focus().clearNodes().unsetAllMarks().run()
        },
        {
            icon: faUndo,
            title: 'Undo',
            action: () => editor.chain().focus().undo().run()
        },
        {
            icon: faRedo,
            title: 'Redo',
            action: () => editor.chain().focus().redo().run()
        }
    ];

    /* const bubbleMenuItems = [
        {
            icon: faHeader,
            title: 'Heading',
            action: () =>
                editor.chain().focus().toggleHeading({ level: 3 }).run(),
            isActive: () => editor.isActive('heading', { level: 3 })
        },
        {
            icon: faParagraph,
            title: 'Paragraph',
            action: () => editor.chain().focus().setParagraph().run(),
            isActive: () => editor.isActive('paragraph')
        },
        {
            type: 'divider'
        },
        {
            icon: faBold,
            title: 'Bold',
            action: () => editor.chain().focus().toggleBold().run(),
            isActive: () => editor.isActive('bold')
        },
        {
            icon: faItalic,
            title: 'Italic',
            action: () => editor.chain().focus().toggleItalic().run(),
            isActive: () => editor.isActive('italic')
        },
        {
            icon: faStrikethrough,
            title: 'Strikethrough',
            action: () => editor.chain().focus().toggleStrike().run(),
            isActive: () => editor.isActive('strike')
        },
        {
            icon: faUnderline,
            title: 'Underline',
            action: () => editor.chain().focus().toggleUnderline().run(),
            isActive: () => editor.isActive('underline')
        },
        {
            icon: faFillDrip,
            type: 'menu',
            title: 'Text Color',
            MenuRender: ColorPicker,
            menuProps: {
                handleChange: (color) =>
                    editor.chain().focus().setColor(color).run()
            }
        },
        {
            icon: faHighlighter,
            title: 'Highlight',
            action: () => editor.chain().focus().toggleHighlight().run(),
            isActive: () => editor.isActive('highlight')
        },
        {
            type: 'divider'
        },
        {
            icon: faAlignLeft,
            title: 'Left Align',
            action: () => editor.chain().focus().setTextAlign('left').run(),
            isActive: () => editor.isActive({ textAlign: 'left' })
        },
        {
            icon: faAlignCenter,
            title: 'Center Align',
            action: () => editor.chain().focus().setTextAlign('center').run(),
            isActive: () => editor.isActive({ textAlign: 'center' })
        },
        {
            icon: faAlignRight,
            title: 'Right Align',
            action: () => editor.chain().focus().setTextAlign('right').run(),
            isActive: () => editor.isActive({ textAlign: 'right' })
        },
        {
            type: 'divider'
        },
        {
            icon: faListUl,
            title: 'Bullet List',
            action: () => editor.chain().focus().toggleBulletList().run(),
            isActive: () => editor.isActive('bulletList')
        },
        {
            icon: faListOl,
            title: 'Ordered List',
            action: () => editor.chain().focus().toggleOrderedList().run(),
            isActive: () => editor.isActive('orderedList')
        },
        /*  {
            icon: faListCheck,
            title: 'Task List',
            action: () => editor.chain().focus().toggleTaskList().run(),
            isActive: () => editor.isActive('taskList')
        },
        {
            type: 'divider'
        },
        {
            icon: faQuoteRight,
            title: 'Blockquote',
            action: () => editor.chain().focus().toggleBlockquote().run(),
            isActive: () => editor.isActive('blockquote')
        },
        {
            icon: faWindowMinimize,
            title: 'Horizontal Rule',
            action: () => editor.chain().focus().setHorizontalRule().run()
        }
    ];

    const floatingMenuItems = [
        {
            icon: faHeader,
            title: 'Heading',
            action: () =>
                editor.chain().focus().toggleHeading({ level: 3 }).run(),
            isActive: () => editor.isActive('heading', { level: 3 })
        },
        {
            icon: faParagraph,
            title: 'Paragraph',
            action: () => editor.chain().focus().setParagraph().run(),
            isActive: () => editor.isActive('paragraph')
        },
        {
            type: 'divider'
        },
        {
            icon: faListUl,
            title: 'Bullet List',
            action: () => editor.chain().focus().toggleBulletList().run(),
            isActive: () => editor.isActive('bulletList')
        },
        {
            icon: faListOl,
            title: 'Ordered List',
            action: () => editor.chain().focus().toggleOrderedList().run(),
            isActive: () => editor.isActive('orderedList')
        },
        {
            icon: faWindowMinimize,
            title: 'Horizontal Rule',
            action: () => editor.chain().focus().setHorizontalRule().run()
        }
    ]; */

    useEffect(() => {
        if (!originalValue.current) {
            originalValue.current = value;
        }
        else if (value === originalValue.current || isNullOrEmpty(value)) {
            editor.commands.setContent(value);
        }
    }, [value]); //eslint-disable-line

    return (
        <div
            className={[
                styles.container,
                readonly ? styles.readonly : null
            ].join(' ')}
        >
            {editor && !readonly && (
                <div className={styles.ribbon}>
                    <div className={styles.leftOptions}>
                        {leftRibbonItems.map((item, index) => (
                            <Fragment key={index}>
                                {item.type === 'divider' ? (
                                    <div className={styles.divider}></div>
                                ) : item.type === 'menu' ? (
                                    <DropdownMenuItem {...item} />
                                ) : (
                                    <MenuItem {...item} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                    <div className={styles.rightOptions}>
                        {rightRibbonItems.map((item, index) => (
                            <Fragment key={index}>
                                {item.type === 'divider' ? (
                                    <div className={styles.divider}></div>
                                ) : item.type === 'menu' ? (
                                    <DropdownMenuItem {...item} />
                                ) : (
                                    <MenuItem {...item} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
            <EditorContent
                editor={editor}
                className={styles.editorContent}
                readOnly={readonly}
                style={{
                    height: readonly
                        ? null
                        : lines
                        ? `${lines * 20}px`
                        : '120px',
                    minHeight: readonly
                        ? null
                        : lines
                        ? `${lines * 20}px`
                        : '120px'
                }}
            />
            {/* <FloatingMenu editor={editor}><CustomMenu items={floatingMenuItems}/></FloatingMenu>
            <BubbleMenu editor={editor}>
                <CustomMenu items={bubbleMenuItems} />
            </BubbleMenu> */}
        </div>
    );
};

const MenuItem = ({ icon, title, action, isActive }) => (
    <TextTooltip
        tooltip={title}
        position={{
            x: 'center',
            y: 'bottom'
        }}
        children={
            <p
                title={title}
                onMouseDown={(e) => {
                    action();
                    e.preventDefault();
                    e.stopPropagation();
                }}
                formAction="none"
                className={[
                    styles.item,
                    isActive?.() ? styles.active : null
                ].join(' ')}
            >
                <FontAwesomeIcon icon={icon} />
            </p>
        }
        hoverTrigger="always"
        hoverDelay={600}
    />
);

const DropdownMenuItem = ({ icon, title, isActive, MenuRender, menuProps }) => {
    const menuRef = useRef();

    const [toggled, setToggle] = useToggle();

    const handleClickOutside = () => {
        setToggle(false);
    };

    useOutsideClick(menuRef, handleClickOutside);

    return (
        <div className={styles.dropdownMenuItem} ref={menuRef}>
            <p
                title={title}
                onMouseDown={(e) => {
                    setToggle((toggled) => !toggled);
                    e.preventDefault();
                    e.stopPropagation();
                }}
                formAction="none"
                className={[
                    styles.item,
                    isActive?.() || toggled ? styles.active : null
                ].join(' ')}
            >
                <FontAwesomeIcon icon={icon} />
            </p>
            {toggled && (
                <div className={styles.dropdownMenu}>
                    {MenuRender && <MenuRender {...menuProps} />}
                </div>
            )}
        </div>
    );
};

/* const CustomMenu = ({ items }) => {
    return (
        <div className={styles.bubbleMenu}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    {item.type === 'divider' ? (
                        <div className={styles.divider}></div>
                    ) : item.type === 'menu' ? (
                        <DropdownMenuItem {...item} />
                    ) : (
                        <MenuItem {...item} />
                    )}
                </Fragment>
            ))}
        </div>
    );
}; */

export default RichTextInput;
