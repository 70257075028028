import React, { useRef } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useToggle from '../../../hooks/useToggle';

import Loader from '../Loader';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from '../../../styles/general/Button.module.scss';

const Button = ({
    type = 'primary',
    variant = 'filled',
    onClick,
    label,
    loading,
    icon,
    iconPosition = 'left',
    iconProps = {},
    disabled = false,
    className,
    buttonRef,
    formAction,
    linkTo,
    newTab = false
}) =>
    linkTo ? (
        <Link
            to={disabled ? null : linkTo}
            target={newTab ? '_blank' : null}
            className={[
                styles.button,
                styles[type],
                styles[variant],
                disabled ? styles.disabled : null,
                className
            ].join(' ')}
        >
            {icon && iconPosition === 'left' && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    <FontAwesomeIcon icon={icon} {...iconProps} />
                </div>
            )}
            {label && <div className={styles.label}>{label}</div>}
            {icon && iconPosition === 'right' && (
                <div className={[styles.icon, styles.right].join(' ')}>
                    <FontAwesomeIcon icon={icon} {...iconProps} />
                </div>
            )}
            {loading && (
                <div className={styles.loader}>
                    <Loader className={styles[`${type}-loader`]} />
                </div>
            )}
        </Link>
    ) : (
        <button
            className={[
                styles.button,
                styles[type],
                styles[variant],
                disabled ? styles.disabled : null,
                className
            ].join(' ')}
            ref={buttonRef}
            onClick={disabled ? null : onClick}
            type={formAction ?? 'button'}
            formAction={formAction ?? 'submit'}
            disabled={disabled || loading}
        >
            {icon && iconPosition === 'left' && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    {loading ? (
                        <Loader className={styles[`${type}-loader`]} />
                    ) : (
                        <FontAwesomeIcon icon={icon} {...iconProps} />
                    )}
                </div>
            )}
            {!icon && loading && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    <Loader className={styles[`${type}-loader`]} />
                </div>
            )}
            {label && <div className={styles.label}>{label}</div>}
            {icon && iconPosition === 'right' && (
                <div className={[styles.icon, styles.right].join(' ')}>
                    {loading ? (
                        <Loader className={styles[`${type}-loader`]} />
                    ) : (
                        <FontAwesomeIcon icon={icon} {...iconProps} />
                    )}
                </div>
            )}
        </button>
    );

Button.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    loading: PropTypes.bool,
    icon: PropTypes.object,
    iconProps: PropTypes.object,
    type: PropTypes.oneOf([
        'primary',
        'Primary',
        'secondary',
        'Secondary',
        'grayscale',
        'Grayscale'
    ]),
    className: PropTypes.string
};

const DropdownButton = ({
    type = 'primary',
    variant = 'filled',
    options = [],
    handleClick = () => null,
    label,
    loading,
    icon,
    iconProps = {},
    disabled = false,
    className
}) => {
    const buttonRef = useRef();

    const [toggled, handleToggle] = useToggle();
    useOutsideClick(buttonRef, () => handleToggle(false));

    const _handleClick = (option) => {
        handleToggle(false);
        if (option?.handleClick) option.handleClick(option?.key);
        else handleClick(option?.key ? option.key : option);
    };

    return (
        <button
            className={[styles.dropdownButton, className].join(' ')}
            ref={buttonRef}
        >
            <div
                className={[
                    styles.button,
                    styles[type],
                    styles[variant],
                    disabled ? styles.disabled : null,
                    toggled ? styles.toggled : null
                ].join(' ')}
                onClick={disabled ? null : () => handleToggle()}
            >
                {icon && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={[styles.icon, styles.left].join(' ')}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
                {label && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={styles.label}
                    >
                        {label}
                    </div>
                )}
                <div className={styles.dropdownIcon}>
                    <FontAwesomeIcon icon={toggled ? faCaretUp : faCaretDown} />
                </div>
                <div className={styles.loader}>
                    {loading && <Loader className={styles[`${type}-loader`]} />}
                </div>
            </div>
            {toggled && (
                <div className={styles.menu}>
                    {options.map((option) => (
                        <DropdownButtonOption
                            option={option}
                            handleClick={_handleClick}
                            key={option}
                        />
                    ))}
                </div>
            )}
        </button>
    );
};

const DropdownButtonOption = ({ option, handleClick }) => (
    <div className={styles.option} onClick={handleClick.bind(this, option)}>
        {option?.icon && (
            <div>
                <FontAwesomeIcon
                    icon={option.icon}
                    {...(option.iconProps ?? {})}
                />
            </div>
        )}
        <p>{option?.label ?? option}</p>
    </div>
);

export default Button;
export { DropdownButton };
