import { useRef } from 'react';

import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import RichTextInput from '../../../general/input/RichTextInput';
import TextInput from '../../../general/input/TextInput';
import Modal from '../../../general/modal/Modal';
import MOPFileUpload from '../MOPFileUpload';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';

import styles from '../../../../styles/apps/mop/modal/EditMOPStepModal.module.scss';

const EditMOPStepModal = ({ handleClose, handleSaveStep, record }) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const {
        handleSubmit,
        control,
        setError,
        setValue,
        getValues,
        clearErrors,
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            assignedTo: record?.assignedTo ?? '',
            attachments: record?.attachments,
            description: record?.description ?? '',
            backoutDescription: record?.backoutDescription ?? '',
            index: record?.index,
            id: record?.id,
            removedAttachmentIds: [],
            mopStepId: record?.mopStepId
        }
    });

    const { append: appendAttachment, remove: removeAttachment } =
        useFieldArray({
            name: 'attachments',
            control: control
        });

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const handleSave = (data) => {
        enqueueSnackbar({
            variant: 'success',
            message: 'Step Saved',
            autoHideDuration: 1500
        });
        handleSaveStep(data);
    };

    const handleRemoveAttachment = (index, attachment) => {
        if (attachment.attachmentId) {
            setValue('removedAttachmentIds', [
                ...getValues('removedAttachmentIds'),
                attachment.attachmentId
            ]);
        }
        removeAttachment(index);
    };

    return (
        <Modal
            open
            dismissable
            handleClose={handleClose}
            style={{ content: { width: '565px' } }}
        >
            <Modal.Title icon={faPencil}>Edit Step</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={(e) => {
                            handleSubmit(handleSave)(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name="assignedTo"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Assigned To">
                                        <TextInput
                                            autoFocus
                                            value={value}
                                            placeholder="Assigned To"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="description"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Description">
                                        <RichTextInput
                                            value={value}
                                            placeholder="Description"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="backoutDescription"
                                control={control}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel label="Backout Process">
                                        <RichTextInput
                                            value={value}
                                            placeholder="Backout Process"
                                            onChange={onChange}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div className={styles.row}>
                            <Controller
                                name="attachments"
                                control={control}
                                render={({ field: { value } }) => (
                                    <MOPFileUpload
                                        fields={value}
                                        append={appendAttachment}
                                        remove={handleRemoveAttachment}
                                    />
                                )}
                            />
                        </div>
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Save" onClick={handleSubmitClicked} />
            </Modal.Actions>
        </Modal>
    );
};

export default EditMOPStepModal;
