import { useState } from 'react';

import { isNullOrEmpty } from '../../../js/services/validation';
import Button from '../../general/input/Button';
import DeleteMOPStepModal from './modal/DeleteMOPStepModal';
import EditMOPStepModal from './modal/EditMOPStepModal';
import NewMOPStepModal from './modal/NewMOPStepModal';
import MOPStepEditCard from './MOPStepEditCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import styles from '../../../styles/apps/mop/MOPSteps.module.scss';

const MOPSteps = ({
    control,
    message,
    append,
    move,
    remove,
    update,
    readonly = false,
    fields
}) => {
    const [modal, setModal] = useState({
        new: false,
        delete: false,
        edit: false
    });

    const handleAddItem = (data) => {
        handleCloseModal('new');
        append(data);
    };

    const handleSaveItem = (data) => {
        handleCloseModal('edit');
        update(data?.index, data);
    };

    const handleRemoveItem = (id) => {
        handleCloseModal('delete');
        remove(id);
    };

    const handleOpenModal = (key, data) => {
        if (readonly) return;

        setModal((modal) => ({
            ...modal,
            [key]: data ?? true
        }));
    };

    const handleCloseModal = (key) => {
        setModal((modal) => ({
            ...modal,
            [key]: false
        }));
    };

    const handleDrag = ({ source, destination }) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.bodyContainer}>
                <div className={styles.stepsContainer}>
                    {!readonly ? (
                        <DragDropContext onDragEnd={handleDrag}>
                            {!isNullOrEmpty(fields) ? (
                                <Droppable droppableId="mop-steps">
                                    {(provided, snapshot) => (
                                        <div
                                            className={styles.stepsContainer}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {fields?.map?.((field, index) => (
                                                <Draggable
                                                    key={`step[${index}]`}
                                                    draggableId={`item-${index}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            key={field.id}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                        >
                                                            <MOPStepEditCard
                                                                control={
                                                                    control
                                                                }
                                                                field={field}
                                                                index={index}
                                                                dragHandleProps={
                                                                    provided.dragHandleProps
                                                                }
                                                                handleDelete={handleOpenModal.bind(
                                                                    this,
                                                                    'delete',
                                                                    index
                                                                )}
                                                                handleEdit={handleOpenModal.bind(
                                                                    this,
                                                                    'edit',
                                                                    {
                                                                        ...(field ??
                                                                            {}),
                                                                        index: index
                                                                    }
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            ) : (
                                <p className={styles.noStepsMessage}>
                                    {message ??
                                        'There are no steps associated with this MOP'}
                                </p>
                            )}
                        </DragDropContext>
                    ) : (
                        <div className={styles.stepsContainer}>
                            {fields?.map?.((field, index) => (
                                <div key={field.id}>
                                    <MOPStepEditCard
                                        field={field}
                                        index={index}
                                        readonly
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                {!readonly && (
                    <div className={styles.actions}>
                        <Button
                            icon={faPlus}
                            label="Add Step"
                            type="secondary"
                            onClick={handleOpenModal.bind(this, 'new')}
                        />
                    </div>
                )}
            </div>
            {modal.edit && (
                <EditMOPStepModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleSaveStep={handleSaveItem}
                    record={modal.edit}
                />
            )}
            {modal.new && (
                <NewMOPStepModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleCreateStep={handleAddItem}
                />
            )}
            {modal.delete !== false && (
                <DeleteMOPStepModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleRemove={handleRemoveItem}
                    id={modal.delete}
                />
            )}
        </div>
    );
};

export default MOPSteps;
