import React from 'react';

import useUser from '../../hooks/useUser';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import styles from '../../styles/navigation/Tabs.module.scss';

const Tabs = ({ tabs = [], active, onChange = () => null }) => {
    const navigate = useNavigate();
    const { userHasPermissions } = useUser();

    const handleChange = (tab) => {
        if (tab.link) navigate(tab.link);
        /* if(tab.param) setSearchParams({
            [tab?.param?.key]: tab?.param?.value
        }); */
        onChange(tab.key);
    };

    return (
        <div className={styles.tabs}>
            <div className={styles.tabContainer}>
                {tabs
                    ?.filter((tab) => {
                        if (!tab.permission) return true;
                        return userHasPermissions(
                            tab.permission,
                            tab.condition
                        );
                    })
                    ?.map((tab) => (
                        <div
                            key={tab.key}
                            className={[
                                styles.container,
                                active === tab.key ? styles.active : null
                            ].join(' ')}
                            onClick={handleChange.bind(this, tab)}
                        >
                            {tab?.label}
                        </div>
                    ))}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
            link: PropTypes.string
        })
    ),
    active: PropTypes.string,
    onChange: PropTypes.func
};

export default Tabs;

const TabGroup = ({ children, selectedIndex, variant = 'tabs' }) => {
    const mapChildren = (children, role) => {
        if (children && !Array.isArray(children)) children = [children];
        if (role === 'List') {
            return children
                ?.filter?.((child) => child.type && child.type.role === role)
                ?.map?.((child, index) =>
                    React.cloneElement(child, {
                        children: mapChildren(child.props.children, 'Tab')
                    })
                );
        } else if (role === 'Panel') {
            return children
                ?.filter?.((child) => child.type && child.type.role === role)
                ?.map?.((child, index) =>
                    React.cloneElement(child, {
                        selected: selectedIndex === index
                    })
                );
        } else if (role === 'Tab') {
            return children
                ?.filter?.((child) => child.type && child.type.role === role)
                ?.map?.((child, index) =>
                    React.cloneElement(child, {
                        selected: selectedIndex === index
                    })
                );
        }
    };

    return (
        <div
            className={
                variant === 'tabs'
                    ? styles.tabGroupContainer
                    : variant === 'stepper'
                    ? styles.tabGroupStepperContainer
                    : null
            }
        >
            {mapChildren(children, 'List')}
            {mapChildren(children, 'Panel')}
        </div>
    );
};

TabGroup.propTypes = {
    selectedIndex: PropTypes.number,
    variant: PropTypes.oneOf(['tabs', 'stepper'])
};

const TabList = ({ children }) => {
    return <div className={styles.tabListContainer}>{children}</div>;
};
TabList.role = 'List';

const Tab = ({ children, disabled, selected, handleSelect }) => {
    return (
        <div
            className={[
                styles.tabContainer,
                selected && styles.selected,
                disabled && styles.disabled
            ].join(' ')}
            onClick={handleSelect}
        >
            {children}
        </div>
    );
};
Tab.role = 'Tab';

const TabPanel = ({ children, selected }) => {
    return <div className={styles.panelContainer}>{selected && children}</div>;
};
TabPanel.role = 'Panel';

export { TabGroup, TabList, TabPanel, Tab };
