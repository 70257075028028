import { useState } from 'react';

import TextInput from '../../general/input/TextInput';
import MOPFileUpload from './MOPFileUpload';
import { faCheck, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parseHtml from 'html-react-parser';

import styles from '../../../styles/apps/mop/MOPStepCard.module.scss';

const MOPStepCard = ({
    step,
    descriptionKey = 'description',
    completedKey = 'completed',
    commentKey = 'mopComments',
    handleStepChecked,
    handleComments,
    handleAttachmentAdd,
    handleAttachmentRemove
}) => {
    const [showComments, setShowComments] = useState(false);

    const _handleComments = (e) => {
        handleComments(step?.id, e.target.value);
    };

    const _handleAttachmentAdd = (attachment) => {
        handleAttachmentAdd(step?.id, attachment);
    };

    const _handleAttachmentRemove = (id) => {
        handleAttachmentRemove(step?.id, id);
    };

    return (
        <div>
            <div className={styles.stepContainer}>
                <div className={styles.sequence}>#{step.sequence + 1}</div>
                <div className={styles.stepBodyContainer}>
                    <div className={styles.stepHeader}>
                        <FontAwesomeIcon
                            icon={faUser}
                            color="var(--xlight-text-color)"
                        />
                        <p>{step.assignedTo}</p>
                    </div>
                    <div className={styles.stepBody}>
                        {parseHtml(step[descriptionKey])}
                    </div>
                    <div className={styles.stepAttachments}>
                        <MOPFileUpload
                            fields={step?.attachments}
                            append={_handleAttachmentAdd}
                            remove={_handleAttachmentRemove}
                        />
                    </div>
                    {step.preMopComments && (
                        <div className={styles.stepBody}>
                            <b>Pre-MOP Comments: </b>
                            {step.preMopComments}
                        </div>
                    )}
                    {step.mopComments && (
                        <div className={styles.stepBody}>
                            <b>MOP Comments: </b>
                            {step.mopComments}
                        </div>
                    )}
                    <div className={styles.stepComments}>
                        {showComments || step?.[commentKey] ? (
                            <TextInput
                                lines={3}
                                placeholder="Comments"
                                onChange={_handleComments}
                                value={step?.[commentKey] ?? ''}
                                autoFocus
                            />
                        ) : (
                            <div
                                onClick={setShowComments.bind(this, true)}
                                className={styles.addComments}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                <p>Add Comments</p>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={[
                        styles.checkboxContainer,
                        step[completedKey]
                            ? styles.completed
                            : styles.incomplete
                    ].join(' ')}
                    onClick={
                        !step[completedKey]
                            ? handleStepChecked?.bind(this, step.id)
                            : null
                    }
                >
                    <FontAwesomeIcon icon={faCheck} />
                </div>
            </div>
            <div className={styles.completedAt}>
                {step.completedOn
                    ? `Marked complete at: ${new Intl.DateTimeFormat('en-US', {
                          timeStyle: 'short'
                      }).format(new Date(step.completedOn))}`
                    : ''}
            </div>
        </div>
    );
};

export default MOPStepCard;
