import useUser from '../../hooks/useUser';

import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const ConditionalRoute = ({ elements }) => {
    const { permissions, userHasPermissions, userHasJobPermissions } =
        useUser();

    return !permissions
        ? null
        : (isObject(elements) &&
              elements[
                  Object.keys(elements)?.find?.(
                      (e) =>
                          e !== 'default' &&
                          (!elements[e].permission ||
                              userHasPermissions(elements[e].permission) ||
                              userHasJobPermissions(elements[e].permission)) &&
                          (!elements[e].permissions ||
                              (elements[e]?.condition === 'or'
                                  ? userHasPermissions(
                                        elements[e]?.permissions,
                                        'or'
                                    ) ||
                                    userHasJobPermissions(
                                        elements[e]?.permissions,
                                        'or'
                                    )
                                  : userHasPermissions(
                                        elements[e]?.permissions
                                    ) ||
                                    userHasJobPermissions(
                                        elements[e]?.permissions
                                    ))) &&
                          (!elements[e].permissionSet ||
                              userHasPermissions(elements[e]?.permissionSet) ||
                              userHasJobPermissions(elements[e]?.permissionSet))
                  ) ?? 'default'
              ]?.component) ?? <Navigate to="/" />;
};

ConditionalRoute.propTypes = {
    elements: PropTypes.object
};

export default ConditionalRoute;
