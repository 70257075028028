import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import styles from '../../../../styles/apps/mop/modal/DeleteMOPStepModal.module.scss';

const DeleteMOPStepModal = ({ id, handleClose, handleRemove }) => {
    const { enqueueSnackbar } = useSnackbar();

    const _handleRemove = () => {
        enqueueSnackbar({
            variant: 'success',
            message: 'Step Removed',
            autoHideDuration: 1500
        });
        handleRemove(id);
    };

    return (
        <Modal open dismissable handleClose={handleClose}>
            <Modal.Title icon={faTrash}>Delete Step</Modal.Title>
            <Modal.Body>
                <div className={styles.confirmationMessage}>
                    Are you sure you want to delete this step?
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button label="Confirm" onClick={_handleRemove} />
            </Modal.Actions>
        </Modal>
    );
};

export default DeleteMOPStepModal;
