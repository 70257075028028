import React, { useEffect, useState } from 'react';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../styles/general/Copy.module.scss';

const Copy = ({ value, icon, classes = {}, children }) => {
    const [tooltip, setTooltip] = useState(null);

    const copyToClipboard = () => {
        try {
            navigator.clipboard.writeText(value);
            setTooltip('Copied!');
        } catch (e) {
            setTooltip('Unable to copy.');
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setTooltip(null);
        }, 2000);

        return () => (timer ? clearTimeout(timer) : null);
    }, [tooltip]);

    return (
        <div className={styles.container}>
            <div className={styles.copyContainer}>
                {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
                <div onClick={copyToClipboard}>
                    {children ? (
                        children
                    ) : (
                        <p
                            className={[styles.copy, classes?.container].join(
                                ' '
                            )}
                        >
                            <FontAwesomeIcon
                                icon={icon ?? faCopy}
                                className={classes?.icon}
                            />
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

Copy.propTypes = {
    value: PropTypes.string,
    icon: PropTypes.string,
    classes: PropTypes.object
};

export default Copy;
