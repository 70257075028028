import { isNullOrEmpty, isValidDate } from '../../../js/services/validation';
import FileUpload from '../../general/input/FileUpload';
import InputLabel from '../../general/input/InputLabel';
import parseHtml from 'html-react-parser';
import { TextTooltip } from '../../general/Tooltip';
import {
    faGripVertical,
    faPencil,
    faTrash,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPStepEditCard.module.scss';

const MOPStepEditCard = ({
    field,
    index,
    handleEdit,
    handleDelete,
    dragHandleProps,
    readonly
}) => {
    return (
        <div className={styles.container}>
            {!readonly && (
                <div className={styles.dragHandle} {...(dragHandleProps ?? {})}>
                    <FontAwesomeIcon icon={faGripVertical} />
                </div>
            )}
            <p className={styles.index}>#{index + 1}</p>
            <div className={styles.body}>
                <div className={styles.header}>
                    <FontAwesomeIcon
                        icon={faUser}
                        color="var(--xlight-text-color)"
                    />
                    <p>{field.assignedTo}</p>
                </div>
                <div className={styles.descriptionContainer}>
                    <div className={styles.description}>
                        <InputLabel label="Procedure">
                            {parseHtml(field.description)}
                        </InputLabel>
                    </div>
                    {field.backoutDescription && (
                        <div className={styles.description}>
                            <InputLabel label="Backout Procedure">
                                {parseHtml(field.backoutDescription)}
                            </InputLabel>
                        </div>
                    )}
                </div>
                {!isNullOrEmpty(field?.attachments) && (
                    <div className={styles.attachmentContainer}>
                        <FileUpload
                            files={field?.attachments}
                            readOnly
                            downloadSingle
                        />
                    </div>
                )}
                {(field?.preMopComments ||
                    field?.mopComments ||
                    field?.backoutComments) && (
                    <div className={styles.commentsContainer}>
                        {field?.preMopComments && (
                            <InputLabel label="Pre-MOP Walk Comments">
                                {field?.preMopComments}
                            </InputLabel>
                        )}
                        {field?.mopComments && (
                            <InputLabel label="MOP Comments">
                                {field?.mopComments}
                            </InputLabel>
                        )}
                        {field?.backoutComments && (
                            <InputLabel label="Backout Comments">
                                {field?.backoutComments}
                            </InputLabel>
                        )}
                    </div>
                )}
                {(field?.walkCompletedOn || field?.completedOn) && (
                    <div className={styles.timestampContainer}>
                        {field?.walkCompletedOn &&
                            isValidDate(field?.walkCompletedOn) && (
                                <div>
                                    <InputLabel label="Walked">
                                        {new Intl.DateTimeFormat('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(
                                            new Date(field?.walkCompletedOn)
                                        )}
                                    </InputLabel>
                                </div>
                            )}
                        {field?.completedOn &&
                            isValidDate(field.completedOn) && (
                                <div>
                                    <InputLabel label="Executed">
                                        {new Intl.DateTimeFormat('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(field?.completedOn))}
                                    </InputLabel>
                                </div>
                            )}
                            {field?.backoutOn &&
                            isValidDate(field.backoutOn) && (
                                <div>
                                    <InputLabel label="Backed Out">
                                        {new Intl.DateTimeFormat('en-US', {
                                            month: 'short',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(field?.backoutOn))}
                                    </InputLabel>
                                </div>
                            )}
                    </div>
                )}
            </div>
            {readonly && (
                <div>
                    <img srcSet={`${field?.signature} 2x`} alt="" />
                </div>
            )}
            {!readonly && (
                <div className={styles.actions}>
                    <TextTooltip
                        tooltip="Edit"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <FontAwesomeIcon icon={faPencil} onClick={handleEdit} />
                    </TextTooltip>
                    <TextTooltip
                        tooltip="Remove"
                        hoverTrigger="always"
                        hoverDelay={600}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={handleDelete}
                        />
                    </TextTooltip>
                </div>
            )}
        </div>
    );
};

export default MOPStepEditCard;
