import { useRef, useState } from 'react';

import { isNullOrEmpty } from '../../../js/services/validation';
import Button from '../../general/input/Button';
import { TextTooltip } from '../../general/Tooltip';
import DeleteMOPSignatureModal from './modal/DeleteMOPSignatureModal';
import EditMOPSignatureModal from './modal/EditMOPSignatureModal';
import NewMOPSignatureModal from './modal/NewMOPSignatureModal';
import {
    faCheck,
    faPencil,
    faPlus,
    faTimes,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPSignatureEdit.module.scss';

const MOPSignatureEdit = ({ fields, remove, update, append, readonly }) => {
    const addButtonRef = useRef();

    const [modal, setModal] = useState({
        new: false,
        edit: false,
        delete: false
    });

    const handleAddItem = (data) => {
        handleCloseModal('new');
        append(data);
        addButtonRef.current.focus();
    };

    const handleSaveItem = (data) => {
        handleCloseModal('edit');
        update(data?.index, data);
    };

    const handleRemoveItem = (id) => {
        handleCloseModal('delete');
        remove(id);
    };

    const handleOpenModal = (key, data) => {
        if (readonly) return;

        setModal((modal) => ({
            ...modal,
            [key]: data ?? true
        }));
    };

    const handleCloseModal = (key) => {
        setModal((modal) => ({
            ...modal,
            [key]: false
        }));
    };

    return (
        <div className={styles.container}>
            {!isNullOrEmpty(fields) ? (
                <div>
                    <div className={styles.header}>
                        <p className={styles.responsibleParty}>
                            Responsible Party
                        </p>
                        <p className={styles.emailAddress}>Email Address</p>
                        <p className={styles.required}>Required</p>
                        <div className={styles.actions}></div>
                    </div>
                    {fields?.map?.((field, index) => (
                        <div className={styles.row} key={index}>
                            <TextTooltip
                                tooltip={field?.responsibleParty}
                                hoverTrigger="overflow"
                                classes={{
                                    tooltipContainer: styles.responsibleParty
                                }}
                            >
                                {field?.responsibleParty}
                            </TextTooltip>
                            <TextTooltip
                                tooltip={field?.emailAddress}
                                hoverTrigger="overflow"
                                classes={{
                                    tooltipContainer: styles.emailAddress
                                }}
                            >
                                {field?.emailAddress}
                            </TextTooltip>
                            <p className={styles.required}>
                                <FontAwesomeIcon
                                    icon={field?.isRequired ? faCheck : faTimes}
                                />
                            </p>
                            {!readonly && (
                                <div className={styles.actions}>
                                    <FontAwesomeIcon
                                        icon={faPencil}
                                        onClick={handleOpenModal.bind(
                                            this,
                                            'edit',
                                            {
                                                ...(field ?? {}),
                                                index: index
                                            }
                                        )}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={handleOpenModal.bind(
                                            this,
                                            'delete',
                                            index
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <p className={styles.noStepsMessage}>
                    There are no signatures associated with this MOP
                </p>
            )}
            {!readonly && (
                <div className={styles.actions}>
                    <Button
                        label="Add Signature"
                        buttonRef={addButtonRef}
                        icon={faPlus}
                        type="secondary"
                        onClick={handleOpenModal.bind(this, 'new')}
                    />
                </div>
            )}
            {modal.new && (
                <NewMOPSignatureModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleCreateSignature={handleAddItem}
                />
            )}
            {modal.edit !== false && (
                <EditMOPSignatureModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleSaveSignature={handleSaveItem}
                    record={modal.edit}
                />
            )}
            {modal.delete !== false && (
                <DeleteMOPSignatureModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleRemove={handleRemoveItem}
                    id={modal.delete}
                />
            )}
        </div>
    );
};

export default MOPSignatureEdit;
