import { TextTooltip } from '../../general/Tooltip';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPEquipmentCard.module.scss';

const MOPEquipmentCard = ({ handleDelete, handleEdit, field, readonly }) => (
    <div className={styles.container}>
        <div className={styles.label}>
            <TextTooltip tooltip={field?.name} overflowTrigger="always">
                {field?.name}
            </TextTooltip>
        </div>
        {!readonly && (
            <div className={styles.actions}>
                <p onClick={handleDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                </p>
                <p onClick={handleEdit}>
                    <FontAwesomeIcon icon={faPencil} />
                </p>
            </div>
        )}
    </div>
);

export default MOPEquipmentCard;
