import { useState } from 'react';

import LoadingOverlay from '../../general/LoadingOverlay';
import SignatureModal from '../../general/modal/SignatureModal';
import { faCheck, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../../../styles/apps/mop/MOPSignature.module.scss';

const MOPSignature = ({
    disabled,
    loading,
    submitted,
    signature,
    handleSave,
    message,
    loadingMessage
}) => {
    const [showSignature, setShowSignature] = useState(false);

    const handleSignature = (state) => setShowSignature(state);

    return (
        <div>
            <p>{message ?? 'If you approve of this MOP, please sign below.'}</p>
            <div className={styles.signatureContainer}>
                {signature?.signature ? (
                    <img
                        srcSet={`${signature?.signature} 2x`}
                        className={styles.signature}
                        alt="signature"
                    />
                ) : (
                    <div
                        className={[
                            styles.emptySignature,
                            disabled ? styles.disabled : null
                        ].join(' ')}
                    >
                        <p
                            onClick={
                                !disabled
                                    ? handleSignature.bind(this, true)
                                    : null
                            }
                        >
                            <FontAwesomeIcon icon={faSignature} />
                        </p>
                    </div>
                )}
            </div>
            <div className={styles.signerName}>{signature?.name}</div>
            <div className={styles.statusContainer}>
                {loading ? (
                    <LoadingOverlay
                        label={loadingMessage ?? 'Submitting MOP Review...'}
                        labelPosition="right"
                        size="sm"
                        fontSize=".85rem"
                    />
                ) : submitted ? (
                    <div className={styles.submittedStatus}>
                        <FontAwesomeIcon icon={faCheck} /> Review Submitted
                    </div>
                ) : null}
            </div>
            {showSignature && (
                <SignatureModal
                    required
                    header={signature?.name}
                    handleSave={(data) => {
                        handleSave(data);
                        handleSignature(false);
                    }}
                    handleClose={handleSignature.bind(this, false)}
                />
            )}
        </div>
    );
};

export default MOPSignature;
