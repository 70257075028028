import { useRef, useState } from 'react';

import useApi from '../../../../hooks/useApi';
import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import { MopCreatePermissions } from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Dropdown from '../../../general/input/Dropdown';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import ExportMOPModal from '../modal/ExportMOPModal';
import ReviewMOPModal from '../modal/ReviewMOPModal';
import {
    faEye,
    faFileExport,
    faFilter,
    faListCheck,
    faPencilAlt,
    faPlus,
    faSignature,
    faWalking
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useNavigate, useOutletContext } from 'react-router-dom';

const defaultFilterState = {
    equipment: {
        value: '',
        getFilter: (equipment) =>
            equipment === '' || equipment === null
                ? null
                : { Equipment: equipment }
    },
    createdOn: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['CreatedOnTo'] = value.to;
            if (value.from) filterObj['CreatedOnFrom'] = value.from;
            return filterObj;
        }
    },
    proposedDate: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['ProposedStartTo'] = value.to;
            if (value.from) filterObj['ProposedStartFrom'] = value.from;
            return filterObj;
        }
    },
    completionDate: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['CompletionStartTo'] = value.to;
            if (value.from) filterObj['CompletionStartFrom'] = value.from;
            return filterObj;
        }
    },
    name: {
        value: '',
        getFilter: (name) =>
            name === '' || name === null ? null : { Name: name }
    },
    status: {
        value: [
            { id: 1, key: 1, value: 'Awaiting Signatures' },
            { id: 2, key: 2, value: 'Pre-MOP Walk' },
            { id: 3, key: 3, value: 'MOP' },
            { id: 4, key: 4, value: 'Backout' }
        ],
        getFilter: (status) =>
            status === '' || status === null
                ? null
                : { StatusIds: status?.map((status) => status?.id) }
    },
    equipmentIds: {
        value: [],
        getFilter: (equipment) =>
            equipment.length === 0
                ? null
                : { EquipmentIds: equipment.map((equipment) => equipment?.id) }
    },
    completedByIds: {
        value: [],
        getFilter: (completedBy) =>
            completedBy.length === 0
                ? null
                : {
                      CompletedByIds: completedBy.map(
                          (completedBy) => completedBy?.id
                      )
                  }
    }
};

const MOPGrid = () => {
    const gridRef = useRef();
    const navigate = useNavigate();
    const { job } = useOutletContext();
    const { userHasJobPermissions, userHasPermissions } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [modals, setModals] = useState({
        review: false,
        export: false
    });

    const [selected, setSelected] = useState([]);

    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'mop_grid_filters'
    );

    const [{ loading: loadingStatuses, data: statuses }] = useApi(
        '/mop/statuses',
        'GET',
        { manual: false }
    );

    const clearFilters = () => {
        resetFilter();
    };

    const handleFilterChange = (key, value) => {
        setFilter({
            key: key,
            payload: value
        });
    };

    const handleDateChange = (category, key, date) =>
        setFilter({
            key: [category],
            payload: {
                ...filter[category].value,
                [key]: date
            }
        });

    const handleOpenModal = (type, value) => {
        setModals((modals) => ({
            ...modals,
            [type]: value
        }));
    };

    const handleCloseModal = (type) => {
        setModals((modals) => ({
            ...modals,
            [type]: false
        }));
    };

    const handleExport = (option) => {
        if (option === 'Selected Rows' && selected.length <= 0)
            return enqueueSnackbar('No grid rows are selected.', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: false
            });
        handleOpenModal('export', option);
    };

    const handleRowSelected = (rows) => {
        setSelected(rows);
    };

    const handleNew = () => {
        navigate('../new');
    };

    console.log(job.id);

    return (
        <div
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    userHasPermissions(MopCreatePermissions) ||
                    userHasJobPermissions(MopCreatePermissions)
                        ? {
                              type: 'primary',
                              label: 'New',
                              icon: faPlus,
                              onClick: handleNew
                          }
                        : null,
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        handleClick: handleExport,
                        options: ['Selected Rows', 'Filtered Rows']
                    }
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={handleRowSelected}
                selected={selected}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/mop/${job.id}`,
                    record: job.id,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        dataKey: 'name',
                        sortKey: 'name',
                        sortable: true,
                        fixedGrow: 1,
                        width: 240,
                        minWidth: 240,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Scheduled Start/End Date',
                        key: 'date',
                        sortKey: 'date',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>
                                {(rowData.proposedStart ||
                                    rowData.revisedStart) &&
                                    new Intl.DateTimeFormat('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    }).format(
                                        new Date(
                                            rowData.revisedStart ??
                                                rowData.proposedStart
                                        )
                                    )}
                                {' - '}
                                {(rowData.proposedStart ||
                                    rowData.revisedStart) &&
                                    (new Intl.DateTimeFormat('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric'
                                    }).format(
                                        new Date(
                                            rowData.revisedStart ??
                                                rowData.proposedStart
                                        )
                                    ) ===
                                    new Intl.DateTimeFormat('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric'
                                    }).format(
                                        new Date(
                                            rowData.revisedEnd ??
                                                rowData.proposedEnd
                                        )
                                    )
                                        ? new Intl.DateTimeFormat('en-US', {
                                              hour: '2-digit',
                                              minute: '2-digit'
                                          }).format(
                                              new Date(
                                                  rowData.revisedEnd ??
                                                      rowData.proposedEnd
                                              )
                                          )
                                        : new Intl.DateTimeFormat('en-US', {
                                              month: '2-digit',
                                              day: '2-digit',
                                              year: 'numeric',
                                              hour: '2-digit',
                                              minute: '2-digit'
                                          }).format(
                                              new Date(
                                                  rowData.revisedEnd ??
                                                      rowData.proposedEnd
                                              )
                                          ))}
                            </TextCell>
                        )
                    },
                    {
                        title: 'Completed By',
                        key: 'completedBy',
                        dataKey: 'completedBy',
                        sortKey: 'completed',
                        sortable: true,
                        width: 180,
                        minWidth: 180,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData?.displayName}</TextCell>
                        )
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        sortKey: 'status',
                        dataKey: 'status',
                        frozen: 'right',
                        sortable: true,
                        width: 180,
                        minWidth: 180,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData?.name}</TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 150,
                        minWidth: 150,
                        frozen: 'right',
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    (userHasJobPermissions(
                                        MopCreatePermissions
                                    ) ||
                                        userHasPermissions(
                                            MopCreatePermissions
                                        )) &&
                                    (rowData.status?.id === 1 ||
                                        (rowData.status?.id === 2 &&
                                            rowData.mopSignatures?.length >=
                                                1 &&
                                            rowData?.mopSignatures?.some?.(
                                                (signature) =>
                                                    !signature.signature
                                            )))
                                        ? {
                                              icon: faSignature,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'review',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Review & Sign',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    (userHasJobPermissions(
                                        MopCreatePermissions
                                    ) ||
                                        userHasPermissions(
                                            MopCreatePermissions
                                        )) &&
                                    rowData?.status?.id === 2
                                        ? {
                                              icon: faWalking,
                                              type: 'grayscale',
                                              linkTo: `/mop/walk/${rowData?.id}`,
                                              tooltip: {
                                                  tooltip: 'Pre-MOP Walk',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : (userHasJobPermissions(
                                              MopCreatePermissions
                                          ) ||
                                              userHasPermissions(
                                                  MopCreatePermissions
                                              )) &&
                                          rowData?.status?.id === 3
                                        ? {
                                              icon: faListCheck,
                                              type: 'grayscale',
                                              linkTo: `/mop/execute/${rowData?.id}`,
                                              tooltip: {
                                                  tooltip: 'Execute MOP',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    {
                                        icon: faEye,
                                        type: 'grayscale',
                                        linkTo: `/mop/view/${rowData?.id}`,
                                        tooltip: {
                                            tooltip: 'View',
                                            hoverDelay: 650,
                                            hoverTrigger: 'always'
                                        }
                                    },
                                    (userHasJobPermissions(
                                        MopCreatePermissions
                                    ) ||
                                        userHasPermissions(
                                            MopCreatePermissions
                                        )) &&
                                    rowData?.status?.id < 3
                                        ? {
                                              icon: faPencilAlt,
                                              type: 'grayscale',
                                              linkTo: `/mop/edit/${rowData?.id}`,
                                              tooltip: {
                                                  tooltip: 'Edit',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    /* (userHasJobPermissions(
                                        MopCreatePermissions
                                    ) ||
                                        userHasPermissions(
                                            MopCreatePermissions
                                        )) &&
                                    rowData?.status?.id < 3
                                        ? {
                                              icon: faTrashAlt,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'delete',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Delete',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null */
                                ]}
                            />
                        )
                    }
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'MOP Name',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'MOP Name',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'name',
                                                e.target.value
                                            ),
                                        value: filter['name'].value
                                    }
                                },
                                {
                                    label: 'Equipment',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'Equipment',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'equipment',
                                                e.target.value
                                            ),
                                        value: filter['equipment'].value
                                    }
                                },
                                {
                                    label: 'Completed By',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Employee(s)',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'completedByIds'
                                            ),
                                        selected:
                                            filter['completedByIds'].value,
                                        getRowValue: (row) => row?.displayName,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/user/app`
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Status',
                                    component: Dropdown,
                                    props: {
                                        placeholder: 'Select Status(s)',
                                        selected: filter.status?.value,
                                        loading: loadingStatuses,
                                        multiselect: true,
                                        options: statuses?.map((c) => ({
                                            ...c,
                                            key: c.id,
                                            value: c.name
                                        })),
                                        handleSelect: handleFilterChange.bind(
                                            this,
                                            'status'
                                        )
                                    }
                                },
                                /* {
                                    label: 'Created On',
                                    component: DateRange,
                                    props: {
                                        to: filter.createdOn?.value.to
                                            ? new Date(
                                                  filter.createdOn.value.to
                                              )
                                            : null,
                                        from: filter.createdOn?.value.from
                                            ? new Date(
                                                  filter.createdOn.value.from
                                              )
                                            : null,
                                        handleChange: handleDateChange.bind(
                                            this,
                                            'createdOn'
                                        )
                                    }
                                }, */
                                {
                                    label: 'Proposed Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.proposedDate?.value.to
                                            ? new Date(
                                                  filter.proposedDate.value.to
                                              )
                                            : null,
                                        from: filter.proposedDate?.value.from
                                            ? new Date(
                                                  filter.proposedDate.value.from
                                              )
                                            : null,
                                        handleChange: handleDateChange.bind(
                                            this,
                                            'proposedDate'
                                        )
                                    }
                                },
                                {
                                    label: 'Completion Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.completionDate?.value.to
                                            ? new Date(
                                                  filter.completionDate.value.to
                                              )
                                            : null,
                                        from: filter.completionDate?.value.from
                                            ? new Date(
                                                  filter.completionDate.value.from
                                              )
                                            : null,
                                        handleChange: handleDateChange.bind(
                                            this,
                                            'completionDate'
                                        )
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.review && (
                <ReviewMOPModal
                    mop={modals.review}
                    handleClose={handleCloseModal.bind(this, 'review')}
                />
            )}
            {modals.export && (
                <ExportMOPModal
                    filters={filter}
                    handleClose={handleCloseModal.bind(this, 'export')}
                    selected={selected}
                />
            )}
        </div>
    );
};

export default MOPGrid;
