import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from '../../../../styles/apps/quality/modal/NewPermissionModal.module.scss';

const NewPermissionModal = ({ handleClose, jobId, handleNew }) => {
    const submitButtonRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading: updating }, updatePermissions] = useApi(
        `/mop/${jobId}/permissions`,
        'POST',
        { manual: true }
    );

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            users: []
        }
    });

    const handleCreate = (data) => {
        updatePermissions({
            data: {
                userIds: data.users?.map((u) => u.id)
            }
        })
            .then((result) => {
                handleNew(result);
                handleClose();
                enqueueSnackbar('Successfully added permissions.', {
                    variant: 'success',
                    autoHideDuration: 3000
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Failed to update permissions.', {
                    variant: 'error',
                    autoHideDuration: 3000
                });
            });
    };

    const handleSubmitClicked = () => submitButtonRef.current?.click?.();

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title icon={faPlus}>Add Permissions</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.row}>
                            <Controller
                                name={`users`}
                                control={control}
                                rules={{
                                    required: 'You must select an employee.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        error={error?.message}
                                        label="Employees"
                                    >
                                        <Select
                                            placeholder="Select Employee(s)"
                                            selected={value}
                                            pagination={{
                                                url: `/user/app`
                                            }}
                                            getRowValue={(row) =>
                                                row?.displayName
                                            }
                                            handleRowSelection={onChange}
                                            multiselect
                                            sort={['name']}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <div>
                                <ErrorMessage
                                    error={errors.root.serverError.message}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                visibility: 'hidden',
                                opacity: 0
                            }}
                        >
                            <button formAction="submit" ref={submitButtonRef} />
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button
                    label="Cancel"
                    onClick={handleClose}
                    type="secondary"
                    variant="border"
                />
                <Button
                    label="Add"
                    onClick={handleSubmitClicked}
                    loading={updating}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default NewPermissionModal;
