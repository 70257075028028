import { isNullOrEmpty } from '../../../js/services/validation';
import Checkbox from '../../general/input/Checkbox';
import CheckboxGroup from '../../general/input/CheckboxGroup';
import { DateTimePicker } from '../../general/input/DatePicker';
import InputLabel from '../../general/input/InputLabel';
import RichTextInput from '../../general/input/RichTextInput';
import TextInput from '../../general/input/TextInput';
import MOPContacts from './MOPContacts';
import MOPEquipment from './MOPEquipment';
import { Controller } from 'react-hook-form';

import styles from '../../../styles/apps/mop/MOPGeneralEdit.module.scss';

const MOPGeneralEdit = ({
    control,
    appendContact,
    removeContact,
    updateContact,
    appendEquipment,
    removeEquipment,
    resetCounter,
    updateEquipment
}) => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.formBody}>
                <div className={styles.section}>
                    <h2>Edit MOP</h2>
                    <Controller
                        name="backoutRequired"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <Checkbox
                                label="Backout Required"
                                checked={value}
                                handleChange={onChange.bind(this, !value)}
                            />
                        )}
                    />
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: 'You must provide a MOP name.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="MOP Name"
                                required
                                error={error?.message}
                            >
                                <TextInput
                                    value={value}
                                    placeholder="MOP Name"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="project"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputLabel label="Project">
                                <TextInput
                                    disabled
                                    value={value}
                                    placeholder="Project"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="location"
                        control={control}
                        rules={{
                            required: 'You must specify a location.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="Location"
                                required
                                error={error?.message}
                            >
                                <TextInput
                                    value={value}
                                    placeholder="Location"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="generalContractor"
                        control={control}
                        rules={{
                            required: 'You must specify a General Contractor.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="General Contractor"
                                required
                                error={error?.message}
                            >
                                <TextInput
                                    value={value}
                                    placeholder="General Contractor"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="owner"
                        control={control}
                        rules={{
                            required: 'You must specify an Owner.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="Owner"
                                required
                                error={error?.message}
                            >
                                <TextInput
                                    value={value}
                                    placeholder="Owner"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="jobNumber"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputLabel label="Job Number">
                                <TextInput
                                    disabled
                                    value={value}
                                    placeholder="Job Number"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="author"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputLabel label="Author">
                                <TextInput
                                    disabled
                                    value={value}
                                    placeholder="Author"
                                    onChange={onChange}
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="generalDescription"
                        control={control}
                        rules={{
                            required: 'You must specify a general description.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="GENERAL DESCRIPTION OF WORK TO BE PERFORMED"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="General description of work to be performed"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="systemsAffected"
                        control={control}
                        rules={{
                            required: 'You must specify the systems affected.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="SYSTEMS AFFECTED"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Systems affected"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="riskRating"
                        control={control}
                        rules={{
                            required: 'You must specify a risk rating.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="RISK RATING (Check One)"
                                required
                                error={error?.message}
                            >
                                <CheckboxGroup
                                    options={[
                                        {
                                            key: 'Low',
                                            value: 'Low'
                                        },
                                        {
                                            key: 'Medium',
                                            value: 'Medium'
                                        },
                                        {
                                            key: 'High',
                                            value: 'High'
                                        }
                                    ]}
                                    selected={value}
                                    handleChange={onChange}
                                    orientation="column"
                                />
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="tradesInvolved"
                        control={control}
                        rules={{
                            required: 'You must specify the trades involved.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="TRADES INVOLVED"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        lines={3}
                                        value={value}
                                        placeholder="Trades involved"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <div className={styles.row}>
                        <Controller
                            name="revisedStart"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="REVISED START DATE AND TIME:"
                                    style={{
                                        container: {
                                            flex: 1
                                        }
                                    }}
                                    error={error?.message}
                                    labelClick={false}
                                >
                                    <DateTimePicker
                                        isClearable
                                        placeholderText="Start Date/Time"
                                        wrapperClassName={styles.datePicker}
                                        className={styles.datePicker}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </InputLabel>
                            )}
                        />
                        <Controller
                            name="revisedEnd"
                            control={control}
                            render={({
                                field: { value, onChange },
                                fieldState: { error }
                            }) => (
                                <InputLabel
                                    label="REVISED COMPLETION DATE AND TIME:"
                                    style={{
                                        container: {
                                            flex: 1
                                        }
                                    }}
                                    error={error?.message}
                                    labelClick={false}
                                >
                                    <DateTimePicker
                                        isClearable
                                        placeholderText="Completion Date/Time"
                                        wrapperClassName={styles.datePicker}
                                        className={styles.datePicker}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </InputLabel>
                            )}
                        />
                    </div>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>MATERIALS AND METHODS</h2>
                    <Controller
                        name="designatedStorageLocation"
                        control={control}
                        rules={{
                            required:
                                'You must specify the designated storage locations.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="List any designated storage locations for tools and materials:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="List any designated storage locations for tools and materials"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="designatedStagingArea"
                        control={control}
                        rules={{
                            required:
                                'You must specify designated staging areas.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="List any designated equipment staging areas to be used during the installation:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="List any designated equipment staging areas to be used during the installation"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>EQUIPMENT</h2>
                    <Controller
                        name="equipmentModifications"
                        control={control}
                        rules={{
                            required:
                                'You must specify the equipment that will be added, removed, or modified.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="List specific equipment that will be added, removed, or modified in this MOP:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="List specific equipment that will be added, removed, or modified in this MOP"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="locationPPERequirements"
                        control={control}
                        rules={{
                            required:
                                'You must specify the equipment locations requiring PPE.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="List specific equipment locations requiring protection and protection required:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="List specific equipment locations requiring protection and protection required"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>IMPACT</h2>
                    <Controller
                        name="impactedSystems"
                        control={control}
                        rules={{
                            required: 'You must specify the impacted systems.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="Identify the systems that may be affected by the work operations:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Identify the systems that may be affected by the work operations"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="safetyPrecautions"
                        control={control}
                        rules={{
                            required:
                                'You must specify any specific precautions required for safety.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="List any specific precautions required for the personal/equipment safety:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="List any specific precautions required for the personal/equipment safety"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>SERVICE PROTECTION</h2>
                    <Controller
                        name="serviceProtection"
                        control={control}
                        rules={{
                            required:
                                'You must specify action being taken to protect service to critical equipment.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="All action being taken to protect any mechanical/electrical equipment or spaces which are a source or power directly to or indirectly serving critical
            equipment should be listed here:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="All action being taken to protect any mechanical/electrical equipment or spaces which are a source or power directly to or indirectly serving critical
                    equipment should be listed here"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>CONTINGENCY PLAN</h2>
                    <Controller
                        name="contingencyPlan"
                        control={control}
                        rules={{
                            required:
                                'You must specify a backup or contingency plan.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="Provide the backup or contingency plan:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Provide the backup or contingency plan"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>CONTACT LIST</h2>
                    <Controller
                        name="mopContacts"
                        control={control}
                        render={({ field: { value } }) => (
                            <MOPContacts
                                append={appendContact}
                                remove={removeContact}
                                update={updateContact}
                                fields={value}
                            />
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <h2 className={styles.header}>EQUIPMENT</h2>
                    <Controller
                        name="mopEquipment"
                        control={control}
                        render={({ field: { value } }) => (
                            <MOPEquipment
                                append={appendEquipment}
                                remove={removeEquipment}
                                update={updateEquipment}
                                fields={value}
                            />
                        )}
                    />
                </div>
                <div className={styles.section}>
                    <Controller
                        name="securityAccess"
                        control={control}
                        rules={{
                            required:
                                'You must specify any security access needed.'
                        }}
                        render={({
                            field: { value, onChange },
                            fieldState: { error }
                        }) => (
                            <InputLabel
                                label="Security Access:"
                                required
                                error={error?.message}
                            >
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Security Access"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="prerequisites"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputLabel label="Prerequisites:">
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Prerequisites"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                    <Controller
                        name="comments"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <InputLabel label="Comments:">
                                {!isNullOrEmpty(value) && (
                                    <RichTextInput
                                        value={value}
                                        placeholder="Comments"
                                        onChange={onChange}
                                    />
                                )}
                            </InputLabel>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default MOPGeneralEdit;
