import { useEffect } from "react";

const usePersistForm = ({
    watch,
    storage,
    key
}) => {
    useEffect(() => {
        const subscription = watch((value) => {
            if(storage === 'session'){
                window.sessionStorage.setItem(key, JSON.stringify(value));
            }
            else if(storage === 'local'){
                window.localStorage.setItem(key, JSON.stringify(value));
            }
        });
        return () => subscription.unsubscribe?.();
    }, [watch, key, storage]);

    return;
};

export default usePersistForm;