import { useNavigate } from "react-router-dom";

const useNavigationWithPrompt = (isDirty) => {
    const navigate = useNavigate();

    const _navigate = (path) => {
        let confirmed = !isDirty;

        if(!confirmed){
            confirmed = window.confirm('Are you sure you want to leave this page?');
        }

        if(!confirmed) return;

        navigate(path);
    }

    return _navigate;
};

export default useNavigationWithPrompt;