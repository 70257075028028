import useApi from '../../../hooks/useApi';

import { isNullOrEmpty } from '../../../js/services/validation';
import Copy from '../../general/Copy';
import Button from '../../general/input/Button';
import { TextTooltip } from '../../general/Tooltip';
import {
    faCheck,
    faEnvelope,
    faEnvelopeCircleCheck,
    faLink,
    faUserCheck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/MOPSignatureReview.module.scss';

const MOPSignatureReview = ({ fields, mop, readonly }) => {
    const navigate = useNavigate();

    const handleReview = (field) => {
        navigate(`/mop/review/${mop?.id}/${field?.validationString}`);
    };

    return (
        <div className={styles.container}>
            {!isNullOrEmpty(fields) ? (
                <div className={styles.categoriesContainer}>
                    {fields?.filter((field) => field.isRequired)?.length > 0 && (
                        <div className={styles.categoryContainer}>
                            <h3>Required Signatures</h3>
                            <div className={styles.itemsContainer}>
                                {fields
                                    ?.filter((field) => field.isRequired)
                                    ?.map?.((field, index) => (
                                        <SignatureLineItem
                                            field={field}
                                            key={index}
                                            mop={mop}
                                            handleReview={handleReview}
                                            readonly={readonly}
                                        />
                                    ))}
                            </div>
                        </div>
                    )}
                    {fields?.filter((field) => !field.isRequired)?.length >
                        0 && (
                        <div className={styles.categoryContainer}>
                            <div className={styles.categoriesContainer}>
                                <h3>Optional Signatures</h3>
                                <div className={styles.itemsContainer}>
                                    {fields
                                        ?.filter((field) => !field.isRequired)
                                        ?.map?.((field, index) => (
                                            <SignatureLineItem
                                                field={field}
                                                key={index}
                                                mop={mop}
                                                handleReview={handleReview}
                                                readonly={readonly}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <p className={styles.noStepsMessage}>
                    There are no signatures associated with this MOP
                </p>
            )}
        </div>
    );
};

const SignatureLineItem = ({ field, mop, readonly }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [{ data, loading }, sendNotification] = useApi(
        `/mop/form/notification/${field?.id}`,
        'POST',
        { manual: true }
    );

    const handleNotification = () => {
        if (data) return;

        sendNotification()
            .then(() => {
                enqueueSnackbar('Notification sent.', {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Could not send notification.', {
                    variant: 'error'
                });
            });
    };

    return (
        <div className={styles.row}>
            <p className={styles.responsibleParty}>
                {(data?.lastNotifiedOn || field?.lastNotifiedOn) && (
                    <TextTooltip
                        hoverTrigger="always"
                        hoverDelay={300}
                        clickTrigger="always"
                        tooltip={`Last notified: ${new Intl.DateTimeFormat(
                            'en-US',
                            {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            }
                        ).format(
                            new Date(
                                data?.lastNotifiedOn ?? field?.lastNotifiedOn
                            )
                        )}`}
                    >
                        <FontAwesomeIcon
                            icon={faEnvelopeCircleCheck}
                            className={styles.reminder}
                        />
                    </TextTooltip>
                )}
                {field?.responsibleParty}
                {field?.emailAddress ? `(${field?.emailAddress})` : null}
            </p>
            {field?.signature ? (
                <img
                    src={`data:image/png;base64,${field.signature}`}
                    className={styles.signature}
                    alt="signature"
                />
            ) : !readonly ? (
                <div>
                    <div className={styles.actions}>
                        <Copy
                            value={`${window.location.origin}/mop/review/${mop?.id}/${field?.validationString}`}
                        >
                            <Button
                                label="Copy URL"
                                icon={faLink}
                                type="grayscale"
                            />
                        </Copy>
                        {field?.emailAddress && (
                            <Button
                                label={
                                    !!data
                                        ? 'Notification Sent'
                                        : 'Send Notification'
                                }
                                icon={!!data ? faCheck : faEnvelope}
                                type="secondary"
                                loading={loading}
                                disabled={loading}
                                onClick={handleNotification}
                            />
                        )}
                        <Button
                            label="Review"
                            icon={faUserCheck}
                            linkTo={`/mop/review/${mop?.id}/${field?.validationString}`}
                            newTab
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MOPSignatureReview;
