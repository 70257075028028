import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import {
    MopAppReadPermissions,
    MopJobDefaultsPermissions,
    MopPermissionUpdatePermissions
} from '../../../js/services/permissions';
import Button from '../../general/input/Button';
import LoadingModal from '../../general/modal/LoadingModal';
import GridSkeleton from '../../general/skeletons/GridSkeleton';
import TabSkeleton from '../../general/skeletons/TabSkeleton';
import { TextTooltip } from '../../general/Tooltip';
import Tabs from '../../navigation/Tabs';
import ChangeJobModal from '../quality/modal/ChangeJobModal';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import styles from '../../../styles/apps/mop/MOP.module.scss';

const tabs = [
    {
        key: 'forms',
        label: 'MOPs',
        link: `/mop/forms`,
        permission: MopAppReadPermissions
    },
    {
        key: 'job-management',
        label: 'Job Defaults',
        link: `/mop/job-management`,
        permission: MopJobDefaultsPermissions
    },
    {
        key: 'permissions',
        label: 'Permissions',
        link: `/mop/permissions`,
        permission: MopPermissionUpdatePermissions
    }
];

const MOP = () => {
    const match = useMatch({
        path: '/mop/:page',
        end: false
    });

    const { defaultJob: job, setUserJob } = useUser();
    const navigate = useNavigate();

    const [, setSearchParams] = useSearchParams();

    const [{ data, loading }, getJobStatus] = useApi(
        `/mop/${job?.id}/user`,
        'GET',
        { manual: true }
    );

    const [tab, setTab] = useState(match?.params?.page ?? 'forms');
    const [jobModal, setJobModal] = useState(false);

    const handleTabChange = (tab) => setTab(tab);
    const handleChangeJob = (job) => {
        setSearchParams({});
        setUserJob(job);
        setJobModal(false);
    };
    const handleOpenJobModal = () => setJobModal(true);
    const handleCloseJobModal = () => setJobModal(false);

    useEffect(() => {
        document.title = 'CVE Apps - MOP';
    });

    useEffect(() => {
        getJobStatus();
    }, [job]); //eslint-disable-line

    const handleNavigateHome = () => navigate('/');

    return job ? (
        loading || data === null ? (
            <div className={styles.container}>
                <TabSkeleton />
                <GridSkeleton />
                <LoadingModal
                    message={`Checking permissions for job #${job.vistaJobNumber}...`}
                />
            </div>
        ) : data === false ? (
            <div className={styles.container}>
                <div className={styles.noPermissions}>
                    <h2>No Permissions</h2>
                    <p>
                        You do not have permissions to view or edit MOPs for
                        this job. Please select a different job.
                    </p>
                    <Button
                        label="Change Job"
                        type="secondary"
                        icon={faPencilAlt}
                        className={styles.changeJob}
                        onClick={handleOpenJobModal}
                    />
                </div>
                {jobModal && (
                    <ChangeJobModal
                        handleClose={handleCloseJobModal}
                        handleConfirm={handleChangeJob}
                        url='/mop/jobs'
                    />
                )}
            </div>
        ) : (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.tabs}>
                        <Tabs
                            tabs={tabs}
                            active={tab}
                            onChange={handleTabChange}
                        />
                    </div>
                    <div className={styles.rightOptions}>
                        <div className={styles.currentJob}>
                            <TextTooltip
                                tooltip="Change Job"
                                hoverTrigger="always"
                                position={{
                                    x: 'center',
                                    y: 'bottom'
                                }}
                            >
                                <p
                                    className={styles.editJob}
                                    onClick={handleOpenJobModal}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </p>
                            </TextTooltip>
                            <div className={styles.jobDescriptionContainer}>
                                <p className={styles.jobDescription}>
                                    {job.vistaJobNumber} -{' '}
                                    {job.vistaJobDescription}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Outlet
                    context={{ job: job, handleChangeJob: handleOpenJobModal }}
                />
                {jobModal && (
                    <ChangeJobModal
                        handleClose={handleCloseJobModal}
                        handleConfirm={handleChangeJob}
                        url="mop/jobs"
                    />
                )}
            </div>
        )
    ) : (
        <div className={styles.container}>
            <div className={styles.tabs}>
                <TabSkeleton />
            </div>
            <GridSkeleton />
            <ChangeJobModal
                title="Select A Job"
                message="Please select a job from the dropdown below to access the MOP app.
                If you do not see your job, ask a supervisor to have you added to the job.
                "
                url="/mop/jobs"
                handleClose={handleNavigateHome}
                handleConfirm={handleChangeJob}
            />
        </div>
    );
};

export default MOP;
