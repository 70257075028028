import Modal from '../../../general/modal/Modal';
import MOPSignatureReview from '../MOPSignatureReview';
import { faSignature } from '@fortawesome/free-solid-svg-icons';

const ReviewMOPModal = ({ mop, handleClose }) => (
    <Modal open blocking handleClose={handleClose}>
        <Modal.Title icon={faSignature}>MOP Signatures</Modal.Title>
        <Modal.Body>
            <MOPSignatureReview fields={mop?.mopSignatures} mop={mop} />
        </Modal.Body>
        <Modal.Actions />
    </Modal>
);

export default ReviewMOPModal;
