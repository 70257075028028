import { isNullOrEmpty } from '../../../js/services/validation';
import { TextTooltip } from '../../general/Tooltip';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatPhoneNumber} from '../../../js/services/manipulation';

import styles from '../../../styles/apps/mop/MOPContactCard.module.scss';

const MOPContactCard = ({ handleDelete, handleEdit, field, readonly }) => (
    <div className={styles.container}>
        <div className={styles.header}>
            {field.isEmergency && <div className={styles.emergencyIcon}>!</div>}
            <div className={styles.headerText}>
                <TextTooltip overflowTrigger="always" tooltip={field.name}>
                    {field.name}
                </TextTooltip>
            </div>
            {!readonly && (
                <div className={styles.actions}>
                    <p onClick={handleDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </p>
                    <p onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPencil} />
                    </p>
                </div>
            )}
        </div>
        <div
            className={[
                styles.subText,
                isNullOrEmpty(field.company) && styles.notProvided
            ].join(' ')}
        >
            <TextTooltip overflowTrigger="always" tooltip={field.company}>
                {!isNullOrEmpty(field.company) ? field.company : 'No Company'}
            </TextTooltip>
        </div>
        <div
            className={[
                styles.subText,
                isNullOrEmpty(field.title) && styles.notProvided
            ].join(' ')}
        >
            <TextTooltip overflowTrigger="always" tooltip={field.title}>
                {!isNullOrEmpty(field.title) ? field.title : 'No Job Title'}
            </TextTooltip>
        </div>
        <div
            className={[
                styles.subText,
                isNullOrEmpty(field.emailAddress) && styles.notProvided
            ].join(' ')}
        >
            <TextTooltip overflowTrigger="always" tooltip={field.emailAddress}>
                {!isNullOrEmpty(field.emailAddress)
                    ? field.emailAddress
                    : 'No Email Address'}
            </TextTooltip>
        </div>
        <div
            className={[
                styles.subText,
                isNullOrEmpty(field.phoneNumber) && styles.notProvided
            ].join(' ')}
        >
            <TextTooltip overflowTrigger="always" tooltip={field.phoneNumber}>
                {!isNullOrEmpty(field.phoneNumber)
                    ? formatPhoneNumber(field.phoneNumber)
                    : 'No Phone Number'}
            </TextTooltip>
        </div>
    </div>
);

export default MOPContactCard;
