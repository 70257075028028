import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import styles from '../../../../styles/apps/mop/modal/BackoutMOPModal.module.scss';

const BackoutMOPModal = ({ handleClose, handleBackout }) => (
    <Modal open dismissable handleClose={handleClose}>
        <Modal.Title icon={faUndo}>MOP Backout</Modal.Title>
        <Modal.Body>
            <div className={styles.confirmationMessage}>
                Are you sure you want to backout of this MOP?
            </div>
        </Modal.Body>
        <Modal.Actions>
            <Button
                label="Cancel"
                onClick={handleClose}
                type="secondary"
                variant="border"
            />
            <Button label="Confirm" onClick={handleBackout} />
        </Modal.Actions>
    </Modal>
);

export default BackoutMOPModal;
