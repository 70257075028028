import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useNavigationWithPrompt from '../../../hooks/useNavigationWithPrompt';

import { isNullOrEmpty } from '../../../js/services/validation';
import APIError from '../../general/APIError';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import LoadingOverlay from '../../general/LoadingOverlay';
import Bookmark from '../../navigation/Bookmark';
import ResetFormModal from './modal/ResetFormModal';
import MOPFileUpload from './MOPFileUpload';
import MOPGeneralEdit from './MOPGeneralEdit';
import MOPSignatureEdit from './MOPSignatureEdit';
import MOPSignatureReview from './MOPSignatureReview';
import MOPSteps from './MOPSteps';
import {
    faArrowLeft,
    faRefresh,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/EditMOP.module.scss';

const EditMOP = () => {
    const match = useMatch({
        path: '/mop/edit/:id',
        end: false
    });

    const { enqueueSnackbar } = useSnackbar();

    const [resetMop, setResetMop] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const [{ data: mop, loading: loadingMOP, error: mopError }] = useApi(
        `/mop/form/${match?.params?.id}`,
        'GET',
        { manual: false }
    );

    const [{ data: submittedMop, loading: submissionLoading }, submitMOP] =
        useApi(`/mop/${match?.params?.id}`, 'PUT', {
            manual: true
        });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setValue,
        setError,
        formState: { errors, isDirty },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            author: null,
            backoutRequired: false,
            name: null,
            project: null,
            location: null,
            generalContractor: null,
            owner: null,
            jobNumber: '',
            jobId: '',
            generalDescription: null,
            systemsAffected: null,
            riskRating: null,
            tradesInvolved: null,
            revisedStart: null,
            revisedEnd: null,
            designatedStorageLocation: null,
            designatedStagingArea: null,
            equipmentModifications: null,
            locationPPERequirements: null,
            impactedSystems: null,
            safetyPrecautions: null,
            serviceProtection: null,
            contingencyPlan: null,
            pretaskReportCompleted: false,
            energizedPermitStatus: false,
            securityAccess: null,
            comments: null,
            removedAttachmentIds: []
        }
    });

    const navigateWithPrompt = useNavigationWithPrompt(isDirty);
    const navigate = useNavigate();

    const {
        append: appendContact,
        remove: removeContact,
        update: updateContact
    } = useFieldArray({
        name: 'mopContacts',
        control: control
    });

    const {
        fields: signaturesField,
        append: appendSignature,
        remove: removeSignature,
        update: updateSignature,
        replace: replaceSignature
    } = useFieldArray({
        name: 'mopSignatures',
        control: control
    });

    const {
        fields: mopAttachments,
        append: appendMopAttachment,
        remove: removeMopAttachment,
        replace: replaceMopAttachments
    } = useFieldArray({
        name: 'mopAttachments',
        control: control
    });

    const {
        fields: mopStepsField,
        append: appendMopStep,
        remove: removeMopStep,
        move: moveMopStep,
        update: updateMopStep,
        replace: replaceMopStep
    } = useFieldArray({
        name: 'mopSteps',
        control: control
    });

    const {
        append: appendMopEquipment,
        remove: removeMopEquipment,
        update: updateMopEquipment
    } = useFieldArray({
        name: 'mopEquipment',
        control: control
    });

    useEffect(() => {
        if (!mop) return;
        reset({
            ...mop,
            removedAttachmentIds: [],
            author: mop.author.displayName,
            jobNumber: mop.job.vistaJobNumber,
            jobId: mop.job.id,
            project: mop.job.vistaJobDescription,
            revisedStart: mop.revisedStart ? new Date(mop.revisedStart) : null,
            revisedEnd: mop.revisedEnd ? new Date(mop.revisedEnd) : null,
            mopAttachments: mop.mopAttachments.map((ma) => ({
                ...ma.attachment,
                attachmentId: ma.attachment.id,
                preview: `/mop/attachment/${ma.attachment.id}/preview`,
                download: `/mop/attachment/${ma.attachment.id}/download`
            })),
            mopSignatures: mop.mopSignatures.map((ms) => ({
                ...ms,
                mopSignatureId: ms.id
            })),
            mopSteps: mop.mopSteps.map((ms) => ({
                ...ms,
                attachments: ms.mopAttachments?.map(ma => ({
                    ...ma.attachment,
                    attachmentId: ma.attachment.id,
                    preview: `/mop/attachment/${ma.attachment.id}/preview`,
                    download: `/mop/attachment/${ma.attachment.id}/download` 
                })),
                mopStepId: ms.id
            })),
            pretaskReportCompleted: mop.pretaskReportCompleted
                ? 'completed'
                : null
        });
        setInitialLoad(false);
    }, [mop, reset]);

    const handleCreate = (data) => {
        if (data.revisedStart > data.revisedEnd) {
            return setError('revisedEnd', {
                message: 'End date must be after start date.'
            });
        }

        let formData = new FormData();
        formData.append(
            'jsonData',
            JSON.stringify({
                ...data,
                mopAttachments: data?.mopAttachments
                    ?.filter?.((ma) => !!ma.attachmentId)
                    ?.map?.((ma) => ({
                        ...ma,
                        id: ma.attachmentId
                    })),
                mopSignatures:
                    data?.mopSignatures?.map?.((ms) => ({
                        ...ms,
                        id: ms.mopSignatureId
                    })) ?? [],
                mopSteps:
                    data?.mopSteps?.map?.((ms, index) => ({
                        ...ms,
                        id: ms.mopStepId,
                        sequence: index
                    })) ?? [],
                pretaskReportCompleted: !!data.pretaskReportCompleted
            })
        );

        data.mopAttachments
            ?.filter?.((ma) => !ma.attachmentId)
            ?.forEach((attachment) =>
                formData.append(
                    `file_${attachment.file.name}/${attachment.file.size}/${attachment.file.type}`,
                    attachment.file
                )
            );

        data.mopSteps?.forEach((step, stepIndex) => {
            step.attachments
                ?.filter?.((a) => !a.attachmentId)
                ?.forEach((attachment) =>
                    formData.append(
                        `filestep-${stepIndex}-${attachment.file.name}-${attachment.file.size}-${attachment.file.type}`,
                        attachment.file
                    )
                );
        });

        submitMOP({
            data: formData
        })
            .then((data) => {
                handleReset();
                enqueueSnackbar({
                    variant: 'success',
                    message: 'MOP Updated',
                    autoHideDuration: 1500
                });
                if (data?.mopSignatures?.length === 0) navigate('/mop/forms');
            })
            .catch((err) => {
                console.error(err);
                if (
                    err?.response?.data?.detail ===
                    'Revised end date must be after start date.'
                ) {
                    setError('revisedEnd', {
                        message: err?.response?.data?.detail
                    });
                } else {
                    enqueueSnackbar('Could not update MOP, please try again.', {
                        variant: 'error',
                        autoHideDuration: 4000
                    });
                }
            });
    };

    const handleReturnToMOP = () => navigateWithPrompt('/mop/forms');

    const handleReset = () => {
        handleCloseReset();
        reset({
            ...mop,
            author: mop.author.displayName,
            jobNumber: mop.job.vistaJobNumber,
            jobId: mop.job.id,
            project: mop.job.vistaJobDescription,
            removedAttachmentIds: []
        });

        replaceMopAttachments(
            mop.mopAttachments?.map((ma) => ({
                ...{
                    ...ma.attachment,
                    attachmentId: ma.attachment.id,
                    preview: `mop/${mop.id}/attachments/${ma.attachment.id}/preview`,
                    download: `mop/${mop.id}/attachments/${ma.attachment.id}/download`
                }
            }))
        );
        replaceMopStep(
            mop.mopSteps.map((ms) => ({
                ...ms,
                attachments: ms.attachments?.map(a => ({
                    ...a,
                    preview: `mop/${mop.id}/attachments/${a.id}/preview`,
                    download: `mop/${mop.id}/attachments/${a.id}/download`
                })),
                mopStepId: ms.id
            }))
        );
        replaceSignature(
            mop.mopSignatures.map((ms) => ({
                ...ms,
                mopSignatureId: ms.id
            }))
        );
    };

    const handleCloseReset = () => setResetMop(false);

    const handleOpenReset = () => setResetMop(true);

    const handleRemoveAttachment = (index, attachment) => {
        if (attachment.attachmentId) {
            setValue('removedAttachmentIds', [
                ...getValues('removedAttachmentIds'),
                attachment.attachmentId
            ]);
        }
        removeMopAttachment(index);
    };

    return (
        <div className={styles.container}>
            {!submittedMop ? (
                (loadingMOP || initialLoad || (!mop && !mopError)) ? (
                    <div className={styles.loadingContainer}>
                        <LoadingOverlay label="Loading MOP..." />
                    </div>
                ) : mop?.walkSignature ? (
                    <div className={styles.errorContainer}>
                        <p>This MOP has been walked and cannot be edited.</p>
                        <Button
                            variant="text"
                            icon={faArrowLeft}
                            label="Back to MOPs"
                            linkTo="/mop/forms"
                        />
                    </div>
                ) : mopError ? (
                    <APIError
                        error={mopError}
                        defaultTitle={'Error Encountered'}
                        defaultMessage={
                            'Could not retrieve the requested MOP. Please refresh and try again.'
                        }
                        actions={[
                            {
                                variant: 'text',
                                icon: faArrowLeft,
                                label: 'Back to MOPs',
                                linkTo: '/mop/forms'
                            }
                        ]}
                    />
                ) : (
                    <FormProvider
                        handleSubmit={handleSubmit}
                        control={control}
                        {...methods}
                    >
                        <form
                            autoComplete="true"
                            onSubmit={handleSubmit(handleCreate)}
                            noValidate={true}
                            className={styles.form}
                        >
                            <Bookmark
                                panelContainerClass={
                                    styles.bookmarkPanelContainer
                                }
                                overlayBreakpoint={1300}
                            >
                                <Bookmark.List>
                                    <Bookmark.Section>
                                        <Bookmark.Option>
                                            General Information
                                        </Bookmark.Option>
                                        <Bookmark.Option>
                                            Attachments
                                        </Bookmark.Option>
                                        <Bookmark.Option>Steps</Bookmark.Option>
                                        <Bookmark.Option>
                                            Signatures
                                        </Bookmark.Option>
                                        <Bookmark.Option>
                                            Submit
                                        </Bookmark.Option>
                                    </Bookmark.Section>
                                    <Bookmark.Footer>
                                        <Bookmark.Link
                                            icon={faRefresh}
                                            handleClick={handleOpenReset}
                                        >
                                            Undo Changes
                                        </Bookmark.Link>
                                        <Bookmark.Link
                                            icon={faArrowLeft}
                                            handleClick={handleReturnToMOP}
                                        >
                                            Back to MOPs
                                        </Bookmark.Link>
                                    </Bookmark.Footer>
                                </Bookmark.List>
                                <Bookmark.Panel>
                                    <MOPGeneralEdit
                                        control={control}
                                        appendContact={appendContact}
                                        removeContact={removeContact}
                                        updateContact={updateContact}
                                        appendEquipment={appendMopEquipment}
                                        removeEquipment={removeMopEquipment}
                                        updateEquipment={updateMopEquipment}
                                    />
                                </Bookmark.Panel>
                                <Bookmark.Panel>
                                    <MOPFileUpload
                                        append={appendMopAttachment}
                                        remove={handleRemoveAttachment}
                                        fields={mopAttachments}
                                    />
                                </Bookmark.Panel>
                                <Bookmark.Panel>
                                    <MOPSteps
                                        fields={mopStepsField}
                                        control={control}
                                        name="mopSteps"
                                        append={appendMopStep}
                                        move={moveMopStep}
                                        remove={removeMopStep}
                                        update={updateMopStep}
                                    />
                                </Bookmark.Panel>
                                <Bookmark.Panel>
                                    <MOPSignatureEdit
                                        append={appendSignature}
                                        update={updateSignature}
                                        remove={removeSignature}
                                        fields={signaturesField}
                                    />
                                </Bookmark.Panel>
                                <Bookmark.Panel>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            margin: '30px 0',
                                            width: 'calc(min(800px, 100%))',
                                            gap: '20px'
                                        }}
                                    >
                                        {!isNullOrEmpty(errors) ? (
                                            <ErrorMessage error="Could not update the MOP, one or more required fields are not valid." />
                                        ) : (
                                            <p
                                                style={{
                                                    height: '32px',
                                                    width: '100%'
                                                }}
                                            />
                                        )}
                                        <Button
                                            label="Save MOP"
                                            icon={faSave}
                                            loading={submissionLoading}
                                            formAction="submit"
                                        />
                                    </div>
                                </Bookmark.Panel>
                            </Bookmark>
                        </form>
                    </FormProvider>
                )
            ) : (
                <div className={styles.submittedContainer}>
                    <h3>MOP Updated Successfully</h3>
                    <div>
                        <Button
                            variant="text"
                            label="Back to MOPs"
                            icon={faArrowLeft}
                            linkTo="/mop"
                        />
                    </div>
                    <div className={styles.notificationDisclaimer}>
                        Send email notifications to those that need to sign
                        below.
                    </div>
                    <MOPSignatureReview
                        mop={submittedMop}
                        fields={submittedMop?.mopSignatures}
                    />
                </div>
            )}
            {resetMop && (
                <ResetFormModal
                    handleClose={handleCloseReset}
                    handleReset={handleReset}
                />
            )}
        </div>
    );
};

export default EditMOP;
