import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { isNullOrEmpty } from '../../../js/services/validation';
import APIError from '../../general/APIError';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import CheckboxGroup from '../../general/input/CheckboxGroup';
import InputLabel from '../../general/input/InputLabel';
import LogoLoader from '../../general/LogoLoader';
import BackoutMOPModal from './modal/BackoutMOPModal';
import MOPContacts from './MOPContacts';
import MOPSignature from './MOPSignature';
import MOPStepCard from './MOPStepCard';
import {
    faArrowLeft,
    faUndo,
    faUserMinus,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/ExecuteMOP.module.scss';

const ExecuteMOP = () => {
    const match = useMatch({
        path: '/mop/execute/:id',
        end: false
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [backout, setBackout] = useState(false);
    const [mopSteps, setMopSteps] = useState([]);
    const [signature, setSignature] = useState(0);
    const [contacts, setContacts] = useState(false);
    const [pretaskReport, setPretaskReport] = useState(null);
    const [electricalWorkPermit, setElectricalWorkPermit] = useState(null);
    const [pretaskReportError, setPretaskReportError] = useState(null);
    const [electricalWorkPermitError, setElectricalWorkPermitError] =
        useState(null);
    const [, setIsDirty] = useState(false);

    const [{ data: mop, loading: loadingMOP, error: mopError }, getMOP] =
        useApi(`/mop/form/${match?.params?.id}`, 'GET', { manual: true });

    const [{ data: savedMOP, loading: loadingSubmit }, submitMOP] = useApi(
        `/mop/execute`,
        'PUT',
        { manual: true }
    );

    const [{ loading: backingOutMOP }, backoutMOP] = useApi(
        `/mop/backout/initiate`,
        'PUT',
        { manual: true }
    );

    const { displayName } = useUser();

    useEffect(() => {
        getMOP()
            .then((mop) => {
                setMopSteps(
                    mop?.mopSteps?.map?.((ms) => ({
                        ...ms,
                        attachments: ms.mopAttachments?.map((ma) => ({
                            ...ma.attachment,
                            readOnly: true,
                            download: `/mop/attachment/${ma.attachment.id}/download`,
                            preview: `/mop/attachment/${ma.attachment.id}/preview`,
                            attachmentId: ma.attachment.id
                        })),
                        completed: isNullOrEmpty(ms.description)
                            ? true
                            : ms.completed
                    }))
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }, []); //eslint-disable-line

    const handleStepChecked = (id) => {
        setIsDirty(true);
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                completed: id === ms.id ? !ms.completed : ms.completed,
                completedOn:
                    id === ms.id && !ms.completed ? new Date() : ms.completedOn
            }))
        );
    };

    const handleStepComments = (id, value) => {
        setIsDirty(true);
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                mopComments: id === ms.id ? value : ms.mopComments
            }))
        );
    };

    const toggleContacts = () => setContacts((contacts) => !contacts);

    const handleStepAttachmentAdd = (step, attachments) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                attachments:
                    ms.id === step
                        ? [...(ms.attachments ?? []), ...attachments]
                        : ms.attachments
            }))
        );
    };

    const handleStepAttachmentRemove = (step, id) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                attachments:
                    ms.id === step
                        ? ms.attachments.filter?.((a, index) => index !== id)
                        : ms.attachments
            }))
        );
    };

    const handlePretaskReport = (pretaskReport) => {
        setPretaskReport(pretaskReport);
    };

    const handleElectricalWorkPermit = (workPermit) => {
        setElectricalWorkPermit(workPermit);
    };

    const handleSaveSignature = (signature) => {
        setPretaskReportError(null);
        setElectricalWorkPermitError(null);
        if (pretaskReport === null) {
            enqueueSnackbar('Missing required field(s).', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: true
            });
            return setPretaskReportError(
                'You must acknowledge the pretask plan was completed and signed.'
            );
        } else if (electricalWorkPermit === null) {
            enqueueSnackbar('Missing required field(s).', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: true
            });
            return setElectricalWorkPermitError(
                'You must specify whether an electrical work permit was completed and signed.'
            );
        }

        setSignature(signature);

        let formData = new FormData();

        formData.append(
            'jsonData',
            JSON.stringify({
                pretaskReportCompleted: true,
                energizedPermitStatus: electricalWorkPermit,
                signature: signature?.replace?.('data:image/png;base64,', ''),
                mopSteps: mopSteps?.map?.((ms) => ({
                    ...ms,
                    comments: ms.mopComments
                }))
            })
        );

        mopSteps?.forEach((step, stepIndex) => {
            step.attachments
                ?.filter?.((a) => !a.attachmentId)
                ?.forEach((attachment) =>
                    formData.append(
                        `filestep-${stepIndex}-${attachment.file.name}-${attachment.file.size}-${attachment.file.type}`,
                        attachment.file
                    )
                );
        });

        submitMOP({
            url: `/mop/execute/${match?.params?.id}`,
            data: formData
        })
            .then(() => {
                enqueueSnackbar('MOP has been submitted', {
                    variant: 'success',
                    autoHideDuration: 4000
                });
                navigate('/mop/forms');
            })
            .catch((err) => {
                console.error(err);
                setSignature(null);
                enqueueSnackbar('Could not submit MOP. Please try again.', {
                    variant: 'error',
                    autoHideDuration: 4000
                });
            });
    };

    const handleOpenBackout = () => {
        if (
            mopSteps.find((ms) => ms.completed) &&
            (!pretaskReport || !electricalWorkPermit)
        ) {
            if (!pretaskReport) {
                enqueueSnackbar('Missing required field(s).', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
                return setPretaskReportError(
                    'You must acknowledge the pretask plan was completed and signed.'
                );
            } else if (!electricalWorkPermit) {
                enqueueSnackbar('Missing required field(s).', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
                return setElectricalWorkPermitError(
                    'You must specify whether an electrical work permit was completed and signed.'
                );
            }
        } else {
            setBackout(true);
        }
    };
    const handleCloseBackout = () => setBackout(false);
    const handleBackout = () => {
        handleCloseBackout();

        let stepValidity = mopSteps?.reduce(
            (acc, ms) =>
                acc.sequential
                    ? ms.completed
                        ? {
                              valid: true,
                              sequential: true,
                              index: ms.sequence
                          }
                        : {
                              valid: true,
                              sequential: false,
                              index: acc.index
                          }
                    : ms.completed
                    ? {
                          valid: false,
                          sequential: false,
                          index: acc.index
                      }
                    : acc,
            {
                valid: true,
                sequential: true,
                index: 0
            }
        );

        if (stepValidity.valid) {
            let formData = new FormData();

            formData.append(
                'jsonData',
                JSON.stringify({
                    mopSteps: mopSteps?.map?.((ms) => ({
                        ...ms,
                        comments: ms.mopComments,
                        completed:
                            ms.sequence <= stepValidity.index
                                ? ms.completed
                                : false
                    }))
                })
            );

            mopSteps?.forEach((step, stepIndex) => {
                step.attachments
                    ?.filter?.((a) => !a.attachmentId)
                    ?.forEach((attachment) =>
                        formData.append(
                            `filestep-${stepIndex}-${attachment.file.name}-${attachment.file.size}-${attachment.file.type}`,
                            attachment.file
                        )
                    );
            });

            backoutMOP({
                url: `/mop/backout/initiate/${match?.params?.id}`,
                data: formData
            })
                .then(() => {
                    navigate(`/mop/backout/${match?.params?.id}`);
                })
                .catch((err) => {
                    console.error(err);
                    setSignature(null);
                    enqueueSnackbar(
                        'Could not backout of MOP. Please try again.',
                        {
                            variant: 'error',
                            autoHideDuration: 4000
                        }
                    );
                });
        } else {
            enqueueSnackbar(
                'You cannot skip steps when triggering a backout.',
                {
                    variant: 'error',
                    autoHideDuration: 3000
                }
            );
        }
    };

    return (
        <div className={styles.container}>
            {mopError ? (
                <APIError
                    error={mopError}
                    defaultMessage={
                        'An error was encountered while retrieving the MOP.'
                    }
                    defaultTitle={'Error encountered, please try again'}
                    actions={[
                        {
                            variant: 'text',
                            label: 'Back to MOPs',
                            icon: faArrowLeft,
                            linkTo: '/mop/forms'
                        }
                    ]}
                />
            ) : !mop || loadingMOP ? (
                <LogoLoader />
            ) : !mop?.walkSignature ? (
                <div className={styles.savedContainer}>
                    <p>This MOP has not been walked yet.</p>
                    <Button
                        icon={faArrowLeft}
                        label="Back to MOPs"
                        type="secondary"
                        linkTo="/mop/forms"
                    />
                </div>
            ) : mop?.signature || mop?.backoutSignature ? (
                <div className={styles.savedContainer}>
                    <p>This MOP has already been completed.</p>
                    <Button
                        icon={faArrowLeft}
                        label="Back to MOPs"
                        type="secondary"
                        linkTo="/mop/forms"
                    />
                </div>
            ) : mop?.backoutInitiated ? (
                <Navigate to={`/mop/backout/${match?.params?.id}`} />
            ) : (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerInnerContainer}>
                            <div className={styles.stepCount}>
                                <p>
                                    <span className={styles.finishedSteps}>
                                        {
                                            mopSteps.filter(
                                                (ms) => ms.completed
                                            ).length
                                        }
                                    </span>
                                    /{mopSteps.length} steps completed
                                </p>
                            </div>
                            <h4>Execute MOP</h4>
                            <div>
                                <Button
                                    icon={faUndo}
                                    label="Execute Backout"
                                    type="secondary"
                                    onClick={handleOpenBackout}
                                    loading={backingOutMOP}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.bodyInnerContainer}>
                            <div className={styles.contacts}>
                                <Button
                                    icon={contacts ? faUserMinus : faUserPlus}
                                    onClick={toggleContacts}
                                    label={
                                        contacts
                                            ? 'Hide Contacts'
                                            : 'Show Contacts'
                                    }
                                    className={styles.contactsButton}
                                />
                                {contacts && (
                                    <MOPContacts
                                        fields={mop?.mopContacts}
                                        readonly
                                    />
                                )}
                            </div>
                            <InputLabel
                                label="Pretask Planning Report"
                                required
                                error={pretaskReportError}
                            >
                                <CheckboxGroup
                                    options={[
                                        {
                                            key: 'completed',
                                            value: 'Completed and Signed'
                                        }
                                    ]}
                                    selected={pretaskReport}
                                    handleChange={handlePretaskReport}
                                />
                            </InputLabel>
                            <InputLabel
                                label="Electrical Work Permit"
                                required
                                error={electricalWorkPermitError}
                            >
                                <CheckboxGroup
                                    options={[
                                        {
                                            key: 'completed',
                                            value: 'Completed and Signed'
                                        },
                                        {
                                            key: 'not applicable',
                                            value: 'Not Applicable'
                                        }
                                    ]}
                                    handleChange={handleElectricalWorkPermit}
                                    selected={electricalWorkPermit}
                                />
                            </InputLabel>
                            {mopSteps?.map?.((ms) => (
                                <MOPStepCard
                                    step={ms}
                                    descriptionKey="description"
                                    completedKey="completed"
                                    commentKey="mopComments"
                                    handleStepChecked={handleStepChecked}
                                    handleComments={handleStepComments}
                                    handleAttachmentAdd={
                                        handleStepAttachmentAdd
                                    }
                                    handleAttachmentRemove={
                                        handleStepAttachmentRemove
                                    }
                                />
                            ))}
                            {mopSteps.every((ms) => ms.completed) && (
                                <div className={styles.submitContainer}>
                                    {!mopSteps.every((ms) => ms.completed) && (
                                        <ErrorMessage error="All steps must be marked as completed before submitting." />
                                    )}
                                    <MOPSignature
                                        handleSave={handleSaveSignature}
                                        message="Please sign to certify all steps were completed and verified."
                                        loadingMessage="Submitting MOP..."
                                        signature={{
                                            signature: signature,
                                            name: displayName
                                        }}
                                        disabled={
                                            !mopSteps.every(
                                                (ms) => ms.completed
                                            )
                                        }
                                        loading={loadingSubmit}
                                        submitted={savedMOP}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            {backout && (
                <BackoutMOPModal
                    handleClose={handleCloseBackout}
                    handleBackout={handleBackout}
                />
            )}
        </div>
    );
};

export default ExecuteMOP;
