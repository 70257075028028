import { useEffect } from 'react';

import useApi from '../../../hooks/useApi';

import APIError from '../../general/APIError';
import Button from '../../general/input/Button';
import InputLabel from '../../general/input/InputLabel';
import TextInput from '../../general/input/TextInput';
import LoadingOverlay from '../../general/LoadingOverlay';
import MOPContacts from './MOPContacts';
import MOPSignatureEdit from './MOPSignatureEdit';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { enqueueSnackbar } from 'notistack';
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm
} from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import styles from '../../../styles/apps/mop/JobManagement.module.scss';

const JobManagement = () => {
    const { job } = useOutletContext();

    const [{ data: defaults, loading: loadingDefaults, error: defaultsError }] =
        useApi(`/mop/${job?.id}/defaults`, 'GET', {
            manual: false
        });

    const [{ loading: savingDefaults }, saveDefaults] = useApi(
        `/mop/${job?.id}/defaults`,
        'PUT',
        { manual: true }
    );

    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setError,
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            owner: '',
            generalContractor: '',
            mopContacts: [],
            mopSignatures: []
        }
    });

    const {
        append: appendContact,
        remove: removeContact,
        update: updateContact
    } = useFieldArray({
        name: 'mopContacts',
        control: control
    });

    const {
        append: appendSignature,
        remove: removeSignature,
        update: updateSignature
    } = useFieldArray({
        name: 'mopSignatures',
        control: control
    });

    useEffect(() => {
        if (!defaults) return;
        reset({
            owner: defaults.owner ?? '',
            generalContractor: defaults.generalContractor ?? '',
            mopSignatures: defaults.signatures?.map((ms) => ({
                ...ms,
                mopSignatureId: ms.id
            })),
            mopContacts: defaults.contacts?.map((mc) => ({
                ...mc,
                mopContactId: mc.id
            }))
        });
    }, [defaults, reset]);

    const handleCreate = (data) =>
        saveDefaults({
            data: {
                ...data
            }
        })
            .then(() => {
                enqueueSnackbar('Updated defaults', {
                    variant: 'success',
                    autoHideDuration: 5000
                });
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar(
                    'Failed to update defaults, please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 4000
                    }
                );
            });

    return (
        <div className={styles.container}>
            {loadingDefaults ? (
                <div className={styles.loadingContainer}>
                    <LoadingOverlay label="Loading Defaults..." />
                </div>
            ) : defaultsError ? (
                <APIError
                    error={defaultsError}
                    defaultTitle={'Cannot Retrieve Defaults'}
                    defaultMessage={
                        'We ran into issues when retrieving defaults for this job.'
                    }
                />
            ) : (
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <div className={styles.heading}>
                            <h2>MOP Defaults</h2>
                            <p>
                                Change the values that will be pre-filled in new
                                MOPs.
                            </p>
                        </div>
                        <div>
                            <Controller
                                name="generalContractor"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <InputLabel label="GC">
                                        <TextInput
                                            value={value}
                                            onChange={onChange}
                                            placeholder="GC"
                                            style={{
                                                container: { maxWidth: '320px' }
                                            }}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                name="owner"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <InputLabel label="Owner">
                                        <TextInput
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Owner"
                                            style={{
                                                container: { maxWidth: '320px' }
                                            }}
                                        />
                                    </InputLabel>
                                )}
                            />
                        </div>
                        <div>
                            <h2 className={styles.header}>Contacts</h2>
                            <div>
                                <Controller
                                    name="mopContacts"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <MOPContacts
                                            append={appendContact}
                                            remove={removeContact}
                                            update={updateContact}
                                            fields={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div>
                            <h2 className={styles.header}>Signatures</h2>
                            <div>
                                <Controller
                                    name="mopSignatures"
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <MOPSignatureEdit
                                            append={appendSignature}
                                            remove={removeSignature}
                                            update={updateSignature}
                                            fields={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.saveButton}>
                            <Button
                                label="Save Defaults"
                                icon={faSave}
                                formAction="submit"
                                loading={savingDefaults}
                                disabled={savingDefaults}
                            />
                        </div>
                    </form>
                </FormProvider>
            )}
        </div>
    );
};

export default JobManagement;
