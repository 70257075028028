import styles from '../../styles/general/APIError.module.scss';
import Button from './input/Button';

const APIError = ({error, defaultTitle, defaultMessage, actions = []}) =>
    <div className={styles.errorContainer}>
        <div className={styles.error}>
            {error.response?.status ?? 500}
        </div>
        <div className={styles.header}>
            {error.response?.data?.title ?? defaultTitle}
        </div>
        <div className={styles.message}>
            {error.response?.data?.detail ??
                defaultMessage}
        </div>
        <div className={styles.actions}>
            {actions?.map?.(action => <Button
                {...action}
            />)}
        </div>
    </div>;

export default APIError;
