import { useReducer, useRef, useState } from 'react';

import filterReducer from '../../../../js/reducers/filterReducer';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import Select from '../../../general/input/Select';
import DeletePermissionModal from '../modal/DeletePermissionModal';
import NewPermissionModal from '../modal/NewPermissionModal';
import {
    faFilter,
    faPlus,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { useOutletContext } from 'react-router-dom';

const defaultFilterState = {
    user: {
        value: [],
        getFilter: (user) =>
            user.length === 0 ? null : { UserIds: user.map((u) => u.id) }
    },
    permission: {
        value: [],
        getFilter: (permissions) =>
            permissions.length === 0
                ? null
                : {
                      JobPermissionIds: permissions.map(
                          (permission) => permission.key
                      )
                  }
    }
};

const PermissionGrid = () => {
    const gridRef = useRef();
    const { job } = useOutletContext();

    const [filter, dispatchFilter] = useReducer(
        filterReducer,
        defaultFilterState
    );

    const [modals, setModals] = useState({
        new: false,
        edit: false,
        delete: false
    });

    const clearFilters = () =>
        dispatchFilter({
            type: 'resetFilter',
            payload: defaultFilterState
        });

    const handleFilterChange = (key, value) => {
        dispatchFilter({
            type: 'updateFilter',
            key: key,
            payload: value
        });
    };

    const handleOpenModal = (type, value) => {
        setModals((modals) => ({
            ...modals,
            [type]: value
        }));
    };

    const handleCloseModal = (type) => {
        setModals((modals) => ({
            ...modals,
            [type]: false
        }));
    };

    const handleNewPermissions = (permissions) => {
        permissions.forEach((permission) => {
            if (permission?.status === 'new') {
                gridRef?.current?.addRow(permission.user);
            } else {
                gridRef?.current?.modifyRow(
                    permission.user.id,
                    permission.user
                );
            }
        });
    };

    const handleDeletePermission = (id) => {
        gridRef?.current?.removeRow(id);
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    {
                        type: 'primary',
                        label: 'New',
                        icon: faPlus,
                        onClick: handleOpenModal.bind(this, 'new', true)
                    }
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={() => null}
                selected={[]}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/mop/${job.id}/users`,
                    record: job.id,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Employee',
                        key: 'employee',
                        dataKey: 'displayName',
                        sortKey: 'user',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        width: 84,
                        minWidth: 84,
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    {
                                        icon: faTrashAlt,
                                        type: 'grayscale',
                                        onClick: handleOpenModal.bind(
                                            this,
                                            'delete',
                                            rowData
                                        ),
                                        tooltip: {
                                            tooltip: 'Delete',
                                            hoverDelay: 650,
                                            hoverTrigger: 'always'
                                        }
                                    }
                                ]}
                            />
                        )
                    }
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Employee',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Employee',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'user'
                                            ),
                                        selected: filter['user'].value,
                                        getRowValue: (row) => row?.displayName,
                                        getRowId: (row) => row.id,
                                        pagination: {
                                            url: `/mop/${job.id}/users`,
                                            record: job.id
                                        },
                                        sort: ['user']
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.new && (
                <NewPermissionModal
                    jobId={job.id}
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleNew={handleNewPermissions}
                />
            )}
            {modals.delete && (
                <DeletePermissionModal
                    record={modals.delete}
                    job={job.id}
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDeletePermission}
                />
            )}
        </div>
    );
};

export default PermissionGrid;
