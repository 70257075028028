import React, { createRef, useEffect, useRef, useState } from 'react';

import useDimensions from '../../hooks/useDimensions';

import Modal from '../general/modal/Modal';
import { faBars, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../styles/navigation/Bookmark.module.scss';

const Bookmark = ({
    children,
    containerClass,
    panelContainerClass,
    behavior = 'scroll',
    overlayBreakpoint = 900
}) => {
    const [selectedBookmark, setSelectedBookmark] = useState(0);

    const scrollRef = useRef();
    //Create a ref for every panel component for scrollTo
    const panelRefs = useRef(
        children
            ?.filter?.((child) => child.type && child.type.role === 'Panel')
            ?.map?.(() => createRef())
    );

    const handleScroll = () => {
        const scrollBottom =
            scrollRef.current?.scrollTop +
            scrollRef.current?.getBoundingClientRect?.()?.height;
        panelRefs.current?.forEach((panel, index) => {
            if (scrollBottom >= panel.current.offsetTop) {
                setSelectedBookmark(index);
            }
        });
    };

    //Add/remove scroll listener
    useEffect(() => {
        if (behavior !== 'scroll') return;
        const scrollElement = scrollRef?.current;
        scrollElement?.addEventListener('scroll', handleScroll);

        return () => {
            scrollElement?.removeEventListener('scroll', handleScroll);
        };
    }, [behavior]);

    const mapChildren = (children, role) => {
        if (children && !Array.isArray(children)) children = [children];

        switch (role) {
            case 'List':
                return children
                    ?.filter?.(
                        (child) => child.type && child.type.role === role
                    )
                    ?.map?.((child, index) => [
                        React.cloneElement(child, {
                            children: child.props.children,
                            overlayBreakpoint,
                            selectedBookmark,
                            panelRefs,
                            behavior,
                            setSelectedBookmark,
                            key: index
                        })
                    ]);
            case 'Panel':
                return children
                    ?.filter?.(
                        (child) => child.type && child.type.role === role
                    )
                    ?.map?.((child, index) =>
                        React.cloneElement(child, {
                            selected:
                                behavior === 'scroll' ||
                                selectedBookmark === index,
                            panelRef: panelRefs.current?.[index],
                            key: index
                        })
                    );
            default:
                return null;
        }
    };

    return (
        <div
            className={[styles.bookmarkContainer, containerClass].join(' ')}
            ref={scrollRef}
        >
            {mapChildren(children, 'List')}
            <div
                className={[
                    styles.bookmarkPanelContainer,
                    panelContainerClass
                ].join(' ')}
            >
                {mapChildren(children, 'Panel')}
            </div>
        </div>
    );
};

Bookmark.propTypes = {
    selectedIndex: PropTypes.number
};

const BookmarkList = ({
    children,
    behavior,
    overlayBreakpoint,
    selectedBookmark,
    setSelectedBookmark,
    panelRefs
}) => {
    const [overlay, setOverlay] = useState(false);
    const [width] = useDimensions();

    const handleOpenOverlay = () => setOverlay(true);
    const handleCloseOverlay = () => setOverlay(false);

    const handleSelection = (selection) => {
        setSelectedBookmark(selection);
        if (overlay) setOverlay(false);
    };

    return width <= overlayBreakpoint ? (
        <div className={styles.overlayListContainer}>
            {width <= overlayBreakpoint && (
                <div
                    className={styles.overlayButton}
                    onClick={handleOpenOverlay}
                >
                    <FontAwesomeIcon icon={faBars} />
                    <p>Sections</p>
                </div>
            )}
            <Modal
                open={overlay}
                blocking={false}
                handleClose={handleCloseOverlay}
                classes={{
                    content: styles.overlayListModalContent
                }}
            >
                <Modal.Title icon={faBookmark}>Sections</Modal.Title>
                <Modal.Body>
                    <div className={styles.overlayListInnerContainer}>
                        <div className={styles.bookmarkListInnerContainer}>
                            {children
                                ?.filter?.(
                                    (child) =>
                                        child.type &&
                                        child.type.role === 'Section'
                                )
                                ?.map((child, index) =>
                                    React.cloneElement(child, {
                                        children: child.props.children,
                                        selectedBookmark,
                                        behavior,
                                        panelRefs,
                                        setSelectedBookmark: handleSelection,
                                        key: index
                                    })
                                )}
                        </div>
                        <div className={styles.bookmarkListFooterContainer}>
                            {children
                                ?.filter?.(
                                    (child) =>
                                        child.type &&
                                        child.type.role === 'Footer'
                                )
                                ?.map((child, index) =>
                                    React.cloneElement(child, {
                                        children: child.props.children,
                                        selectedBookmark,
                                        behavior,
                                        panelRefs,
                                        setSelectedBookmark,
                                        key: index
                                    })
                                )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    ) : (
        <div className={styles.bookmarkListContainer}>
            <div className={styles.bookmarkListInnerContainer}>
                {children
                    ?.filter?.(
                        (child) => child.type && child.type.role === 'Section'
                    )
                    ?.map((child, index) =>
                        React.cloneElement(child, {
                            children: child.props.children,
                            selectedBookmark,
                            behavior,
                            panelRefs,
                            setSelectedBookmark,
                            key: index
                        })
                    )}
            </div>
            <div className={styles.bookmarkListFooterContainer}>
                {children
                    ?.filter?.(
                        (child) => child.type && child.type.role === 'Footer'
                    )
                    ?.map((child, index) =>
                        React.cloneElement(child, {
                            children: child.props.children,
                            selectedBookmark,
                            behavior,
                            panelRefs,
                            setSelectedBookmark,
                            key: index
                        })
                    )}
            </div>
        </div>
    );
};
BookmarkList.role = 'List';

const BookmarkSection = ({
    children,
    behavior,
    selectedBookmark,
    setSelectedBookmark,
    panelRefs
}) => {
    return (
        <div className={styles.bookmarkSectionContainer}>
            {children.map((child, index) =>
                React.cloneElement(child, {
                    selected: selectedBookmark === index,
                    panelRef: panelRefs.current?.[index],
                    handleSelect: () => {
                        setSelectedBookmark(index);
                        if (behavior === 'scroll') {
                            panelRefs.current?.[
                                index
                            ]?.current?.scrollIntoView?.({
                                //behavior: 'smooth'
                            });
                        }
                    },
                    key: index
                })
            )}
        </div>
    );
};
BookmarkSection.role = 'Section';

const BookmarkOption = ({ children, disabled, selected, handleSelect }) => {
    return (
        <div
            className={[
                styles.optionContainer,
                selected && styles.selected,
                disabled && styles.disabled
            ].join(' ')}
            onClick={handleSelect}
        >
            <div
                className={[styles.option, selected && styles.selected].join(
                    ' '
                )}
            >
                {children}
                <div className={styles.selectedIndicator} />
            </div>
        </div>
    );
};
BookmarkOption.role = 'Option';

const BookmarkPanel = ({ children, panelRef, selected }) => {
    return (
        <div className={styles.panelContainer} ref={panelRef}>
            {selected && children}
        </div>
    );
};
BookmarkPanel.role = 'Panel';

const BookmarkFooter = ({ children }) => {
    return children;
};
BookmarkFooter.role = 'Footer';

const BookmarkLink = ({
    children,
    icon,
    iconPosition = 'left',
    handleClick
}) => {
    return (
        <div className={styles.linkContainer} onClick={handleClick}>
            {icon && iconPosition === 'left' && <FontAwesomeIcon icon={icon} />}
            <p className={styles.link}>{children}</p>
            {icon && iconPosition === 'right' && (
                <FontAwesomeIcon icon={icon} />
            )}
        </div>
    );
};
BookmarkLink.role = 'Link';

Bookmark.Link = BookmarkLink;
Bookmark.List = BookmarkList;
Bookmark.Option = BookmarkOption;
Bookmark.Panel = BookmarkPanel;
Bookmark.Section = BookmarkSection;
Bookmark.Footer = BookmarkFooter;

export default Bookmark;

export {
    BookmarkLink,
    BookmarkList,
    BookmarkOption,
    BookmarkPanel,
    BookmarkSection,
    BookmarkFooter
};
