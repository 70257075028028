/**
 * CERTIFICATIONS
 */
const CertificationReviewGridPermissions = [
    'Certifications.EmployeeCertifications.Review',
    'Certifications.EmployeeCertifications.Verify'
];

const CertificationCreatePermissions = [
    'Certifications.EmployeeCertifications.Create'
];

const CertificationUpdatePermissions = [
    'Certifications.EmployeeCertifications.Update'
];

const CertificationDeletePermissions = [
    'Certifications.EmployeeCertifications.Delete'
];

const CertificationReportPermissions = [
    'Certifications.EmployeeCertifications.Report'
];

const CertificationReviewPermissions = [
    'Certifications.EmployeeCertifications.Review',
    'Certifications.EmployeeCertifications.Verify'
];

const CertificationTypeGridPermissions = [
    'Certifications.CertificationTypes.Read'
];

const CertificationTypeCreatePermissions = [
    'Certifications.CertificationTypes.Create'
];

const CertificationTypeUpdatePermissions = [
    'Certifications.CertificationTypes.Update'
];

/**
 * CONTACTS
 */
const ContactsReadPermissions = 'Contacts.Contacts.Read';

const ContactsCreatePermissions = 'Contacts.Contacts.Create';

const ContactsDeletePermissions = 'Contacts.Contacts.Delete';

const ContactsUpdatePermissions = 'Contacts.Contacts.Update';

const ContactsFlagCreatePermissions = 'Contacts.Flags.Create';

const ContactsFlagDeletePermissions = 'Contacts.Flags.Delete';

const ContactsFlagUpdatePermissions = 'Contacts.Flags.Update';

const ContactsCustodianUpdatePermissions = 'Contacts.Custodian.Update';

/**
 * MOP
 */
const MopCreatePermissions = ['MOP.MOP.Create'];

const MopJobDefaultsPermissions = ['MOP.MOP.JobDefaults'];

const MopPermissionUpdatePermissions = ['MOP.Permissions.Update'];

const MopAppReadPermissions = ['MOP.App.Read'];

/**
 * PRETASK
 */
const PretaskGridPermissions = ['Pretask.Forms.Create'];

const PretaskCreatePermissions = ['Pretask.Forms.Create'];

const PretaskSupervisorGridPermissions = [
    'Pretask.Forms.Read.Avtec',
    'Pretask.Forms.Read.CorporateCombined',
    'Pretask.Forms.Read.CVETechnologies',
    'Pretask.Forms.Read.ECD',
    'Pretask.Forms.Read.Hawaii',
    'Pretask.Forms.Read.Line',
    'Pretask.Forms.Read.Logan',
    'Pretask.Forms.Read.Service',
    'Pretask.Forms.Read.SUD',
    'Pretask.Forms.Read.Teledata',
    'Pretask.Forms.Read.Teledata-Portland'
];

const PretaskReportPermissions = ['Pretask.Forms.Report'];

const PretaskUpdatePermissions = ['Pretask.Forms.Update'];

const PretaskTemplateCreatePermissions = ['Pretask.Templates.Create'];

/**
 * SUGGESTION BOX
 */

const SuggestionBoxReadPermissions = [
    'SuggestionBox.Suggestions.Read.Generald',
    'SuggestionBox.Suggestions.Read.HRd',
    'SuggestionBox.Suggestions.Read.ITd',
    'SuggestionBox.Suggestions.Read.JobSited',
    'SuggestionBox.Suggestions.Read.OfficeFacilities',
    'SuggestionBox.Suggestions.Read.Prefab/Precon/VDC/Survey',
    'SuggestionBox.Suggestions.Read.Safety'
];

const SuggestionBoxReportPermissions = ['SuggestionBox.Suggestions.Report'];

const SuggestionBoxUpdatePermissions = [
    'SuggestionBox.Suggestions.Update.General',
    'SuggestionBox.Suggestions.Update.HR',
    'SuggestionBox.Suggestions.Update.IT',
    'SuggestionBox.Suggestions.Update.JobSite',
    'SuggestionBox.Suggestions.Update.OfficeFacilities',
    'SuggestionBox.Suggestions.Update.Prefab/Precon/VDC/Survey',
    'SuggestionBox.Suggestions.Update.Safety'
];

const SuggestionBoxReviewActionPermissions = {
    General: 'SuggestionBox.Suggestions.Update.General',
    HR: 'SuggestionBox.Suggestions.Update.HR',
    IT: 'SuggestionBox.Suggestions.Update.IT',
    'Job Site': 'SuggestionBox.Suggestions.Update.JobSite',
    'Office Facilities': 'SuggestionBox.Suggestions.Update.OfficeFacilities',
    'Prefab/Precon/VDC/Survey':
        'SuggestionBox.Suggestions.Update.Prefab/Precon/VDC/Survey',
    Safety: 'SuggestionBox.Suggestions.Update.Safety'
};

/**
 * QUALITY PERMISSIONS
 */
const QualityReadPermissions = ['Quality.App.Read'];

const QualityFormSetupPermissions = ['Quality.Form.Setup'];

const QualityFormDeletePermissions = ['Quality.Form.Delete'];

const QualityFormQCPermissions = ['Quality.Form.QC'];

const QualityFormCommissionPermissions = ['Quality.Form.Commission'];

const QualityFormResetPermissions = ['Quality.Form.Reset'];

const QualityPermissionsUpdatePermissions = ['Quality.Permissions.Update'];

const QualityAttachmentTagPermissions = [
    'Quality.Form.Setup',
    'Quality.Form.Reset',
    'Quality.Form.Commission',
    'Quality.Form.QC'
];

/**
 * USER PERMISSIONS
 */

const UserPermissionsReadPermissions = ['Permissions.Users.Read'];

const UserPermissionsUpdatePermissions = ['Permissions.Users.Update'];

export {
    CertificationCreatePermissions,
    CertificationDeletePermissions,
    CertificationReportPermissions,
    CertificationReviewGridPermissions,
    CertificationReviewPermissions,
    CertificationUpdatePermissions,
    CertificationTypeCreatePermissions,
    CertificationTypeGridPermissions,
    CertificationTypeUpdatePermissions,
    ContactsCustodianUpdatePermissions,
    ContactsCreatePermissions,
    ContactsDeletePermissions,
    ContactsFlagCreatePermissions,
    ContactsFlagDeletePermissions,
    ContactsFlagUpdatePermissions,
    ContactsReadPermissions,
    ContactsUpdatePermissions,
    MopCreatePermissions,
    MopJobDefaultsPermissions,
    MopPermissionUpdatePermissions,
    MopAppReadPermissions,
    PretaskGridPermissions,
    PretaskCreatePermissions,
    PretaskSupervisorGridPermissions,
    PretaskReportPermissions,
    PretaskUpdatePermissions,
    PretaskTemplateCreatePermissions,
    SuggestionBoxReadPermissions,
    SuggestionBoxReportPermissions,
    SuggestionBoxUpdatePermissions,
    SuggestionBoxReviewActionPermissions,
    QualityAttachmentTagPermissions,
    QualityReadPermissions,
    QualityFormSetupPermissions,
    QualityFormDeletePermissions,
    QualityFormQCPermissions,
    QualityFormCommissionPermissions,
    QualityFormResetPermissions,
    QualityPermissionsUpdatePermissions,
    UserPermissionsReadPermissions,
    UserPermissionsUpdatePermissions
};
