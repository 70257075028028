import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

import { isNullOrEmpty } from '../../../js/services/validation';
import APIError from '../../general/APIError';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import LogoLoader from '../../general/LogoLoader';
import MOPContacts from './MOPContacts';
import MOPSignature from './MOPSignature';
import MOPStepCard from './MOPStepCard';
import {
    faArrowLeft,
    faUserMinus,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/BackoutMOP.module.scss';

const BackoutMOP = () => {
    const match = useMatch({
        path: '/mop/backout/:id',
        end: false
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [mopSteps, setMopSteps] = useState([]);
    const [signature, setSignature] = useState(0);
    const [contacts, setContacts] = useState(false);

    const [{ data: mop, loading: loadingMOP, error: mopError }, getMOP] =
        useApi(`/mop/form/${match?.params?.id}`, 'GET', { manual: true });

    const [{ data: savedMOP, loading: loadingSubmit }, submitMOP] = useApi(
        `/mop/execute`,
        'PUT',
        { manual: true }
    );

    const toggleContacts = () => setContacts((contacts) => !contacts);

    const { displayName } = useUser();

    useEffect(() => {
        getMOP()
            .then((mop) => {
                setMopSteps(
                    mop?.mopSteps
                        ?.filter?.((ms) => ms.completed)
                        ?.map?.((ms) => ({
                            ...ms,
                            attachments: ms.mopAttachments?.map((ma) => ({
                                ...ma.attachment,
                                readOnly: true,
                                download: `/mop/attachment/${ma.attachment.id}/download`,
                                preview: `/mop/attachment/${ma.attachment.id}/preview`,
                                attachmentId: ma.attachment.id
                            })),
                            completed: isNullOrEmpty(ms.backoutDescription)
                                ? true
                                : ms.completed
                        }))
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }, []); //eslint-disable-line

    const handleStepChecked = (id) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                backoutCompleted:
                    id === ms.id ? !ms.backoutCompleted : ms.backoutCompleted,
                completedOn:
                    id === ms.id && !ms.completedOn ? new Date() : ms.completedOn
            }))
        );
    };

    const handleStepComments = (id, value) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                backoutComments: id === ms.id ? value : ms.backoutComments
            }))
        );
    };

    const handleStepAttachmentAdd = (step, attachments) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                attachments:
                    ms.id === step
                        ? [...(ms.attachments ?? []), ...attachments]
                        : ms.attachments
            }))
        );
    };

    const handleStepAttachmentRemove = (step, id) => {
        setMopSteps((ms) =>
            ms.map((ms) => ({
                ...ms,
                attachments:
                    ms.id === step
                        ? ms.attachments.filter?.((a, index) => index !== id)
                        : ms.attachments
            }))
        );
    };

    const handleSaveSignature = (signature) => {
        setSignature(signature);

        let formData = new FormData();

        formData.append(
            'jsonData',
            JSON.stringify({
                signature: signature?.replace?.('data:image/png;base64,', ''),
                mopSteps: mopSteps.map((ms) => ({
                    ...ms,
                    completed: ms.backoutCompleted,
                    comments: ms.backoutComments
                }))
            })
        );

        mopSteps?.forEach((step) => {
            step.attachments
                ?.filter?.((a) => !a.attachmentId)
                ?.forEach((attachment) =>
                    formData.append(
                        `filestep-${step.sequence}-${attachment.file.name}-${attachment.file.size}-${attachment.file.type}`,
                        attachment.file
                    )
                );
        });

        submitMOP({
            url: `/mop/backout/${match?.params?.id}`,
            data: formData
        })
            .then(() => {
                enqueueSnackbar('MOP backout has been submitted', {
                    variant: 'success',
                    autoHideDuration: 4000
                });
                navigate('/mop/forms');
            })
            .catch((err) => {
                console.error(err);
                setSignature(null);
                enqueueSnackbar(
                    'Could not submit MOP backout. Please try again.',
                    {
                        variant: 'error',
                        autoHideDuration: 4000
                    }
                );
            });
    };

    return (
        <div className={styles.container}>
            {mopError ? (
                <APIError
                    error={mopError}
                    defaultMesage="Could not retrieve the requested MOP. Please refresh and try again."
                    defaultTitle="Error Encountered"
                    actions={[
                        {
                            variant: 'text',
                            icon: faArrowLeft,
                            label: 'Back to MOPs',
                            linkTo: '/mop/forms'
                        }
                    ]}
                />
            ) : !mop || loadingMOP ? (
                <LogoLoader />
            ) : mop?.signature || mop?.backoutSignature ? (
                <div className={styles.savedContainer}>
                    <p>This MOP has already been completed.</p>
                    <Button
                        icon={faArrowLeft}
                        label="Back to MOPs"
                        type="secondary"
                        linkTo="/mop/forms"
                    />
                </div>
            ) : !mop?.backoutInitiated ? (
                <div className={styles.savedContainer}>
                    <p>
                        This MOP cannot be backed out of yet. Please execute the
                        MOP to initiate the backout process.
                    </p>
                    <Button
                        icon={faArrowLeft}
                        label="Back to MOPs"
                        type="secondary"
                        linkTo="/mop/forms"
                    />
                </div>
            ) : (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerInnerContainer}>
                            <div className={styles.stepCount}>
                                <p>
                                    <span className={styles.finishedSteps}>
                                        {
                                            mopSteps.filter(
                                                (ms) => ms.completed
                                            ).length
                                        }
                                    </span>
                                    /{mopSteps.length} steps completed
                                </p>
                            </div>
                            <h4>Backout MOP</h4>
                            <div></div>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.bodyInnerContainer}>
                            <div className={styles.contacts}>
                                <Button
                                    icon={contacts ? faUserMinus : faUserPlus}
                                    onClick={toggleContacts}
                                    label={
                                        contacts
                                            ? 'Hide Contacts'
                                            : 'Show Contacts'
                                    }
                                    className={styles.contactsButton}
                                />
                                {contacts && (
                                    <MOPContacts
                                        fields={mop?.mopContacts}
                                        readonly
                                    />
                                )}
                            </div>
                            {mopSteps
                                ?.sort?.((a, b) => b.sequence - a.sequence)
                                ?.map?.((ms) => (
                                    <MOPStepCard
                                        step={ms}
                                        descriptionKey="backoutDescription"
                                        completedKey="backoutCompleted"
                                        commentKey="backoutComments"
                                        handleStepChecked={handleStepChecked}
                                        handleComments={handleStepComments}
                                        handleAttachmentAdd={
                                            handleStepAttachmentAdd
                                        }
                                        handleAttachmentRemove={
                                            handleStepAttachmentRemove
                                        }
                                    />
                                ))}
                            {mopSteps.every((ms) => ms.backoutCompleted) && (
                                <div className={styles.submitContainer}>
                                    {!mopSteps.every(
                                        (ms) => ms.backoutCompleted
                                    ) && (
                                        <ErrorMessage error="All steps must be marked as completed before submitting." />
                                    )}
                                    <MOPSignature
                                        handleSave={handleSaveSignature}
                                        message="Please sign to certify all steps were completed and verified."
                                        loadingMessage="Submitting MOP backout..."
                                        signature={{
                                            signature: signature,
                                            name: displayName
                                        }}
                                        loading={loadingSubmit}
                                        submitted={savedMOP}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BackoutMOP;
