import { useRef, useState } from 'react';

import useFetch from '../../../../hooks/useFetch';
import useFilterReducer from '../../../../hooks/useFilterReducer';
import useUser from '../../../../hooks/useUser';

import {
    QualityFormDeletePermissions,
    QualityFormResetPermissions,
    QualityFormSetupPermissions
} from '../../../../js/services/permissions';
import ActionCell from '../../../general/grid/cell renderers/ActionCell';
import TextCell from '../../../general/grid/cell renderers/TextCell';
import Filters from '../../../general/grid/Filters';
import Grid from '../../../general/grid/Grid';
import DateRange from '../../../general/input/DateRange';
import Select from '../../../general/input/Select';
import TextInput from '../../../general/input/TextInput';
import DeleteEquipmentFormModal from '../modal/DeleteEquipmentFormModal';
import EditEquipmentFormModal from '../modal/EditEquipmentFormModal';
import ExportEquipmentFormModal from '../modal/ExportEquipmentFormModal';
import NewEquipmentFormModal from '../modal/NewEquipmentFormModal';
import ResetFormModal from '../modal/ResetFormModal';
import {
    faEye,
    faFileDownload,
    faFileExport,
    faFilter,
    faPencilAlt,
    faPlus,
    faRepeat,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { useNavigate, useOutletContext } from 'react-router-dom';

import styles from '../../../../styles/apps/quality/grid/QualityGrid.module.scss';

const defaultFilterState = {
    equipment: {
        value: [],
        getFilter: (equipment) =>
            equipment.length === 0
                ? null
                : { EquipmentIds: equipment.map((e) => e.id) }
    },
    date: {
        value: {
            to: null,
            from: null
        },
        getFilter: (value) => {
            if (!value.to && !value.from) return null;
            let filterObj = {};
            if (value.to) filterObj['DateTo'] = value.to;
            if (value.from) filterObj['DateFrom'] = value.from;
            return filterObj;
        }
    },
    formName: {
        value: '',
        getFilter: (name) =>
            name === '' || name === null ? null : { FormName: name }
    },
    formType: {
        value: [],
        getFilter: (forms) =>
            forms.length === 0
                ? null
                : { FormTypeIds: forms.map((form) => form?.id) }
    },
    step: {
        value: [],
        getFilter: (steps) =>
            steps.length === 0
                ? null
                : { StepIds: steps.map((step) => step?.id) }
    },
    tags: {
        value: [],
        getFilter: (tags) =>
            tags.length === 0 ? null : { TagIds: tags.map((tag) => tag?.id) }
    }
};

const FormGrid = () => {
    const gridRef = useRef();
    const navigate = useNavigate();
    const { job } = useOutletContext();
    const { userHasJobPermissions, userHasPermissions } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [modals, setModals] = useState({
        new: false,
        edit: false,
        reset: false,
        delete: false,
        export: false
    });

    const [selected, setSelected] = useState([]);

    const { filter, setFilter, resetFilter } = useFilterReducer(
        defaultFilterState,
        'quality_form_grid_filters'
    );

    const [{loading: loadingExport}, exportForms] = useFetch('', 'GET', { manual: true });

    const clearFilters = () => {
        resetFilter();
    };

    const handleFilterChange = (key, preservedKeys, value) => {
        let payload = value;

        if(Array.isArray(payload) && !!preservedKeys){
            payload = payload.map?.(value => {
                let modifiedObj = {};
                preservedKeys?.forEach?.((key) => {
                    modifiedObj[key] = value[key];
                });
                return modifiedObj;
            });
        }

        setFilter({
            key: key,
            payload
        });
    };

    const handleDateChange = (key, date) =>
        setFilter({
            key: 'date',
            payload: {
                ...filter['date'].value,
                [key]: date
            }
        });

    const handleEditForm = (id, step) => {
        navigate(`/quality/forms/${id}/${step}`);
    };

    const handleViewForm = ({ id }) => {
        navigate(`/quality/forms/${id}/view`);
    };

    const handleOpenModal = (type, value) => {
        setModals((modals) => ({
            ...modals,
            [type]: value
        }));
    };

    const handleCloseModal = (type) => {
        setModals((modals) => ({
            ...modals,
            [type]: false
        }));
    };

    const handleExport = (option) => {
        if (option === 'Selected Rows' && selected.length <= 0)
            return enqueueSnackbar('No grid rows are selected.', {
                variant: 'error',
                autoHideDuration: 3000,
                preventDuplicate: false
            });
        handleOpenModal('export', option);
    };

    const handleExportCsv = () => {
        const buildUrl = () => {
            let url = `/quality/form/export/csv`;
            let filters = filter;
            //Build filter parameter
            let filterObj = filters
                ? Object.keys(filters)
                      ?.filter(
                          (filter) =>
                              !!filters?.[filter]?.getFilter?.(
                                  filters?.[filter]?.value
                              )
                      )
                      .reduce(
                          (acc, cur) => ({
                              ...acc,
                              ...filters[cur].getFilter?.(filters?.[cur]?.value)
                          }),
                          {}
                      )
                : {};
            filterObj['JobId'] = job.id;

            let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Mountain Standard Time";

            url += `?filter=${JSON.stringify(filterObj)}&jobId=${job.id}&timeZone=${timeZone}`;

            return url;
        };

        exportForms({
            url: buildUrl()
        })
            .then(
                (res) =>
                    new Promise((resolve, reject) => {
                        if (res.ok) resolve(res.blob());
                        else {
                            res.json()
                                .then((data) => {
                                    enqueueSnackbar('Failed to generate CSV', {
                                        variant: 'error',
                                        autoHideDuration: 3000
                                    });
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        }
                    })
            )
            .then((data) => {
                var objectURL = URL.createObjectURL(data);
                let element = document.createElement('a');
                element.href = objectURL;
                element.download = `Quality Form Export.csv`;
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                URL.revokeObjectURL(objectURL);
            })
            .catch((err) => {
                console.error('err', err);
                enqueueSnackbar('Failed to export forms.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            })
            .catch((err) => {
                console.error('err', err);
                enqueueSnackbar('Failed to export forms.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                    preventDuplicate: true
                });
            });
    };

    const handleNewForms = (forms) => {
        gridRef?.current?.addRow(forms);
    };

    const handleEditedForm = (id, form) => {
        gridRef?.current?.modifyRow(id, form);
    };

    const handleDeleteForm = (id) => {
        gridRef?.current?.removeRow(id);
    };

    const handleRowSelected = (rows) => {
        setSelected(rows);
    };

    return (
        <div
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <Grid
                ref={gridRef}
                filters={filter}
                actions={[
                    userHasJobPermissions(QualityFormSetupPermissions) ||
                    userHasPermissions(QualityFormSetupPermissions)
                        ? {
                              type: 'primary',
                              label: 'New',
                              icon: faPlus,
                              onClick: handleOpenModal.bind(this, 'new')
                          }
                        : null,
                    {
                        type: 'secondary',
                        variant: 'border',
                        label: 'Export',
                        icon: faFileExport,
                        handleClick: handleExport,
                        options: ['Selected Rows', 'Filtered Rows']
                    },
                    {
                        type: 'primary',
                        variant: 'border',
                        label: 'Export CSV',
                        icon: faFileDownload,
                        loading: loadingExport,
                        onClick: handleExportCsv
                    }
                ]}
                fixed
                multiselect
                rowSelect
                handleRowSelection={handleRowSelected}
                selected={selected}
                getRowId={(r) => r.id}
                pagination={{
                    url: `/quality/${job.id}/forms`,
                    record: job.id,
                    pageSize: 100
                }}
                columns={[
                    {
                        title: 'Last Modified',
                        key: 'date',
                        dataKey: 'modifiedOn',
                        sortKey: 'date',
                        sortable: true,
                        width: 160,
                        minWidth: 160,
                        cellRenderer: ({ cellData }) =>
                            cellData && (
                                <TextCell>
                                    {cellData &&
                                        new Intl.DateTimeFormat('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        }).format(new Date(cellData))}
                                </TextCell>
                            )
                    },
                    {
                        title: 'Equipment',
                        key: 'equipment',
                        dataKey: 'equipmentName',
                        sortKey: 'equipment',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Form Name',
                        key: 'name',
                        dataKey: 'name',
                        sortKey: 'name',
                        sortable: true,
                        width: 300,
                        minWidth: 300,
                        fixedGrow: 1,
                        cellRenderer: ({ cellData }) => (
                            <TextCell>{cellData}</TextCell>
                        )
                    },
                    {
                        title: 'Form Type',
                        key: 'type',
                        sortKey: 'type',
                        sortable: true,
                        width: 240,
                        minWidth: 240,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{rowData.formTemplate?.name}</TextCell>
                        )
                    },
                    {
                        title: 'Step',
                        key: 'step',
                        sortKey: 'step',
                        sortable: true,
                        width: 175,
                        minWidth: 175,
                        cellRenderer: ({ rowData }) => (
                            <TextCell>{rowData?.currentStep?.name}</TextCell>
                        )
                    },
                    {
                        title: 'Tags',
                        key: 'tags',
                        sortKey: 'tag',
                        sortable: true,
                        width: 90,
                        minWidth: 90,
                        cellRenderer: ({ rowData }) => (
                            <TextCell
                                hoverTrigger="always"
                                clickTrigger="always"
                                hoverDelay={400}
                                tooltip={rowData?.qualityTags?.map?.(
                                    (qt, i) => (
                                        <div key={i}>{qt.name}</div>
                                    )
                                )}
                            >
                                {rowData.qualityTags?.length ? (
                                    <p
                                        className={styles.attachmentLink}
                                    >{`${rowData.qualityTags?.length} tags`}</p>
                                ) : (
                                    ''
                                )}
                            </TextCell>
                        )
                    },
                    {
                        title: ' ',
                        key: 'actions',
                        sortable: false,
                        minWidth: 180,
                        width: 180,
                        frozen: 'right',
                        cellRenderer: ({ rowData }) => (
                            <ActionCell
                                actions={[
                                    ...(rowData.isLocked
                                        ? []
                                        : rowData?.steps
                                              ?.sort?.(
                                                  (a, b) =>
                                                      a.sequenceNumber -
                                                      b.sequenceNumber
                                              )
                                              ?.map?.((step) => {
                                                  return !step.permission ||
                                                      userHasJobPermissions(
                                                          step.permission
                                                      ) ||
                                                      userHasPermissions(
                                                          step.permission
                                                      )
                                                      ? {
                                                            icon:
                                                                step?.icon ??
                                                                'pencil-ruler',
                                                            type: 'grayscale',
                                                            onClick:
                                                                handleEditForm.bind(
                                                                    this,
                                                                    rowData.id,
                                                                    step.action?.toLowerCase?.()
                                                                ),
                                                            tooltip: {
                                                                tooltip:
                                                                    step?.action,
                                                                hoverDelay: 650,
                                                                hoverTrigger:
                                                                    'always'
                                                            }
                                                        }
                                                      : null;
                                              }) ?? []),
                                    {
                                        icon: faEye,
                                        type: 'grayscale',
                                        onClick: handleViewForm.bind(
                                            this,
                                            rowData
                                        ),
                                        tooltip: {
                                            tooltip: 'View',
                                            hoverDelay: 650,
                                            hoverTrigger: 'always'
                                        }
                                    },
                                    userHasJobPermissions(
                                        QualityFormSetupPermissions
                                    ) ||
                                    userHasPermissions(
                                        QualityFormSetupPermissions
                                    )
                                        ? {
                                              icon: faPencilAlt,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'edit',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Edit',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    userHasJobPermissions(
                                        QualityFormResetPermissions
                                    ) ||
                                    userHasPermissions(
                                        QualityFormResetPermissions
                                    )
                                        ? {
                                              icon: faRepeat,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'reset',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Reset Form',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null,
                                    userHasJobPermissions(
                                        QualityFormDeletePermissions
                                    ) ||
                                    userHasPermissions(
                                        QualityFormDeletePermissions
                                    )
                                        ? {
                                              icon: faTrashAlt,
                                              type: 'grayscale',
                                              onClick: handleOpenModal.bind(
                                                  this,
                                                  'delete',
                                                  rowData
                                              ),
                                              tooltip: {
                                                  tooltip: 'Delete',
                                                  hoverDelay: 650,
                                                  hoverTrigger: 'always'
                                              }
                                          }
                                        : null
                                ]}
                            />
                        )
                    }
                ]}
                sidepanel={{
                    filters: {
                        label: 'Filters',
                        icon: faFilter,
                        component: Filters,
                        props: {
                            clearFilters: clearFilters,
                            filters: [
                                {
                                    label: 'Equipment',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Equipment',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'equipment',
                                                ['id', 'name']
                                            ),
                                        selected: filter['equipment'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/equipment`,
                                            record: job.id
                                        },
                                        sort: ['equipment']
                                    }
                                },
                                {
                                    label: 'Form Name',
                                    component: TextInput,
                                    props: {
                                        placeholder: 'Form Name',
                                        onChange: (e) =>
                                            handleFilterChange(
                                                'formName',
                                                null,
                                                e.target.value
                                            ),
                                        value: filter['formName'].value
                                    }
                                },
                                {
                                    label: 'Form Type',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Form Type(s)',
                                        multiselect: true,
                                        filter: {
                                            InUse: {
                                                value: true,
                                                getFilter: () => ({
                                                    InUse: true
                                                })
                                            }
                                        },
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'formType',
                                                ['id', 'name']
                                            ),
                                        selected: filter['formType'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/form/templates`,
                                            record: job.id,
                                            params: {
                                                jobSpecific: true
                                            }
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Tags',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Tag(s)',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'tags',
                                                ['id', 'name']
                                            ),
                                        selected: filter['tags'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: `/quality/${job.id}/tags`,
                                            record: job.id
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Step',
                                    component: Select,
                                    props: {
                                        placeholder: 'Select Step',
                                        multiselect: true,
                                        handleRowSelection:
                                            handleFilterChange.bind(
                                                this,
                                                'step',
                                                ['id', 'name']
                                            ),
                                        selected: filter['step'].value,
                                        getRowValue: (row) => row?.name,
                                        getRowNodeId: (row) => row.id,
                                        pagination: {
                                            url: '/form/steps'
                                        },
                                        sort: ['name']
                                    }
                                },
                                {
                                    label: 'Date',
                                    component: DateRange,
                                    props: {
                                        to: filter.date?.value.to
                                            ? new Date(filter.date.value.to)
                                            : null,
                                        from: filter.date?.value.from
                                            ? new Date(filter.date.value.from)
                                            : null,
                                        handleChange: handleDateChange
                                    }
                                }
                            ]
                        }
                    }
                }}
            />
            {modals.new && (
                <NewEquipmentFormModal
                    handleNew={handleNewForms}
                    handleClose={handleCloseModal.bind(this, 'new')}
                    jobId={job.id}
                />
            )}
            {modals.edit && (
                <EditEquipmentFormModal
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleEdit={handleEditedForm}
                    equipmentForm={modals.edit}
                    jobId={job.id}
                />
            )}
            {modals.reset && (
                <ResetFormModal
                    handleClose={handleCloseModal.bind(this, 'reset')}
                    handleReset={handleEditedForm}
                    equipmentForm={modals.reset}
                />
            )}
            {modals.delete && (
                <DeleteEquipmentFormModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleDelete={handleDeleteForm}
                    equipmentForm={modals.delete}
                />
            )}
            {modals.export && (
                <ExportEquipmentFormModal
                    handleClose={handleCloseModal.bind(this, 'export')}
                    filters={filter}
                    type={modals.export}
                    selected={selected}
                    jobId={job.id}
                />
            )}
        </div>
    );
};

export default FormGrid;
