import { useRef, useState } from 'react';

import { isNullOrEmpty } from '../../../js/services/validation';
import Button from '../../general/input/Button';
import DeleteMOPContactModal from './modal/DeleteMOPContactModal';
import EditMOPContactModal from './modal/EditMOPContactModal';
import NewMOPContactModal from './modal/NewMOPContactModal';
import MOPContactCard from './MOPContactCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from '../../../styles/apps/mop/MOPContacts.module.scss';

const MOPContacts = ({ fields, append, remove, update, readonly }) => {
    const buttonRef = useRef();

    const [modal, setModal] = useState({
        new: false,
        delete: false,
        edit: false
    });

    const handleAddItem = (data) => {
        handleCloseModal('new');
        buttonRef?.current?.focus?.();
        append(data);
    };

    const handleSaveItem = (data) => {
        handleCloseModal('edit');
        buttonRef?.current?.focus?.();
        update(data?.index, data);
    };

    const handleRemoveItem = (id) => {
        handleCloseModal('delete');
        remove(id);
    };

    const handleOpenModal = (key, data) => {
        if (readonly) return;
        setModal((modal) => ({
            ...modal,
            [key]: data ?? true
        }));
    };

    const handleCloseModal = (key) => {
        setModal((modal) => ({
            ...modal,
            [key]: false
        }));
    };

    return (
        <div className={styles.container}>
            <div className={styles.bodyContainer}>
                {!isNullOrEmpty(fields) ? (
                    <div className={styles.contactsContainer}>
                        {fields?.map?.((field, index) => (
                            <MOPContactCard
                                handleDelete={handleOpenModal.bind(
                                    this,
                                    'delete',
                                    index
                                )}
                                handleEdit={handleOpenModal.bind(this, 'edit', {
                                    ...(field ?? {}),
                                    index
                                })}
                                field={field}
                                index={index}
                                key={field.id}
                                readonly={readonly}
                            />
                        ))}
                    </div>
                ) : (
                    <p className={styles.noStepsMessage}>
                        {'There are no contacts associated with this MOP'}
                    </p>
                )}
                {!readonly && (
                    <div className={styles.actions}>
                        <Button
                            icon={faPlus}
                            label="Add Contact"
                            onClick={handleOpenModal.bind(this, 'new')}
                            type="secondary"
                            buttonRef={buttonRef}
                        />
                    </div>
                )}
            </div>
            {modal.new && (
                <NewMOPContactModal
                    handleClose={handleCloseModal.bind(this, 'new')}
                    handleCreateContact={handleAddItem}
                />
            )}
            {modal.delete !== false && (
                <DeleteMOPContactModal
                    handleClose={handleCloseModal.bind(this, 'delete')}
                    handleRemove={handleRemoveItem}
                    id={modal.delete}
                />
            )}
            {modal.edit && (
                <EditMOPContactModal
                    record={modal.edit}
                    handleClose={handleCloseModal.bind(this, 'edit')}
                    handleSaveContact={handleSaveItem}
                />
            )}
        </div>
    );
};

export default MOPContacts;
