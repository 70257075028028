import { useEffect, useState } from 'react';

import useApi from '../../../hooks/useApi';
import usePersistForm from '../../../hooks/usePersistForm';
import useSessionStorage from '../../../hooks/useSessionStorage';
import useUser from '../../../hooks/useUser';

import { tryParseJSON } from '../../../js/services/manipulation';
import {
    isNullOrEmpty,
    objectEqualityCompare
} from '../../../js/services/validation';
import ErrorMessage from '../../general/ErrorMessage';
import Button from '../../general/input/Button';
import Bookmark from '../../navigation/Bookmark';
import ResetFormModal from './modal/ResetFormModal';
import MOPFileUpload from './MOPFileUpload';
import MOPGeneralNew from './MOPGeneralNew';
import MOPSignatureEdit from './MOPSignatureEdit';
import MOPSignatureReview from './MOPSignatureReview';
import MOPSteps from './MOPSteps';
import {
    faArrowLeft,
    faRefresh,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from '../../../styles/apps/mop/NewMOP.module.scss';

const NewMOP = () => {
    const { defaultJob: job, displayName } = useUser();
    const [sessionValue] = useSessionStorage('new-mop', {});
    const { enqueueSnackbar } = useSnackbar();

    const [{ data: mopDefaults }, getDefaults] = useApi(
        `/mop/${job?.id}/defaults`,
        'GET',
        { manual: false }
    );

    const [{ data, loading }, submitMOP] = useApi('/mop', 'POST', {
        manual: true
    });

    const [resetMop, setResetMop] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        watch,
        getValues,
        setError,
        formState: { errors },
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            attachments: [],
            author: displayName,
            backoutRequired: sessionValue['backoutRequired'] ?? false,
            name: sessionValue['name'] ?? '',
            project: job?.vistaJobDescription,
            location: sessionValue['location'] ?? '',
            generalContractor: sessionValue['generalContractor'] ?? '',
            owner: sessionValue['owner'] ?? '',
            jobNumber: job?.vistaJobNumber,
            jobId: job?.id,
            generalDescription: sessionValue['generalDescription'] ?? null,
            systemsAffected: sessionValue['systemsAffected'] ?? null,
            riskRating: sessionValue['riskRating'] ?? null,
            tradesInvolved: sessionValue['tradesInvolved'] ?? '',
            proposedStart: sessionValue['proposedStart']
                ? new Date(sessionValue['proposedStart'])
                : null,
            proposedEnd: sessionValue['proposedEnd']
                ? new Date(sessionValue['proposedEnd'])
                : null,
            designatedStorageLocation:
                sessionValue['designatedStorageLocation'] ?? '',
            designatedStagingArea: sessionValue['designatedStagingArea'] ?? '',
            equipmentModifications:
                sessionValue['equipmentModifications'] ?? '',
            locationPPERequirements:
                sessionValue['locationPPERequirements'] ?? '',
            impactedSystems: sessionValue['impactedSystems'] ?? '',
            safetyPrecautions: sessionValue['safetyPrecautions'] ?? '',
            serviceProtection: sessionValue['serviceProtection'] ?? '',
            contingencyPlan: sessionValue['contingencyPlan'] ?? '',
            pretaskReportCompleted:
                sessionValue['pretaskReportCompleted'] ?? false,
            energizedPermitStatus:
                sessionValue['energizedPermitStatus'] ?? null,
            securityAccess: sessionValue['securityAccess'] ?? '',
            comments: sessionValue['comments'] ?? ''
        }
    });

    usePersistForm({ watch, storage: 'session', key: 'new-mop' });

    const navigate = useNavigate();

    const {
        append: appendContact,
        remove: removeContact,
        update: updateContact,
        replace: replaceContact
    } = useFieldArray({
        name: 'mopContacts',
        control: control
    });

    const {
        fields: signaturesField,
        append: appendSignature,
        remove: removeSignature,
        update: updateSignature,
        replace: replaceSignature
    } = useFieldArray({
        name: 'mopSignatures',
        control: control
    });

    const {
        fields: mopAttachments,
        append: appendMopAttachment,
        remove: removeMopAttachment,
        update: updateMopAttachment,
        replace: replaceMopAttachment
    } = useFieldArray({
        name: 'mopAttachments',
        control: control
    });

    const {
        fields: mopStepsField,
        append: appendMopStep,
        remove: removeMopStep,
        move: moveMopStep,
        update: updateMopStep,
        replace: replaceMopStep
    } = useFieldArray({
        name: 'mopSteps',
        control: control
    });

    const {
        append: appendMopEquipment,
        remove: removeMopEquipment,
        update: updateMopEquipment,
        replace: replaceMopEquipment
    } = useFieldArray({
        name: 'mopEquipment',
        control: control
    });

    useEffect(() => {
        replaceContact(sessionValue['mopContacts'] ?? []);
        replaceSignature(sessionValue['mopSignatures'] ?? []);
        replaceMopStep(sessionValue['mopSteps'] ?? []);
        replaceMopEquipment(sessionValue['mopEquipment'] ?? []);
        replaceMopAttachment(sessionValue['mopAttachments'] ?? []);
    }, [replaceContact, replaceSignature, replaceMopStep, replaceMopEquipment]); //eslint-disable-line

    //TODO: add dirty field to stored object
    useEffect(() => {
        getDefaults()
            .then((data) => {
                let sessionValue = tryParseJSON(
                    sessionStorage.getItem('new-mop')
                );

                let defaults = {
                    attachments: [],
                    backoutRequired: false,
                    name: '',
                    location: '',
                    generalContractor: '',
                    owner: '',
                    generalDescription: null,
                    systemsAffected: null,
                    riskRating: null,
                    tradesInvolved: '',
                    proposedStart: null,
                    proposedEnd: null,
                    designatedStorageLocation: '',
                    designatedStagingArea: '',
                    equipmentModifications: '',
                    locationPPERequirements: '',
                    impactedSystems: '',
                    safetyPrecautions: '',
                    serviceProtection: '',
                    contingencyPlan: '',
                    pretaskReportCompleted: false,
                    energizedPermitStatus: null,
                    securityAccess: '',
                    comments: '',
                    mopSignatures: [],
                    mopContacts: [],
                    mopSteps: [],
                    mopEquipment: []
                };

                let isDirty = !objectEqualityCompare(sessionValue, defaults, [
                    'author',
                    'project',
                    'jobNumber',
                    'jobId'
                ]);

                data.signatures = data.signatures.map?.((s) => ({
                    ...s,
                    isDefault: true
                }));

                data.contacts = data.contacts.map?.((c) => ({
                    ...c,
                    isDefault: true
                }));

                if (!isDirty) {
                    reset({
                        generalContractor: data.generalContractor,
                        owner: data.owner
                    });
                    if (
                        sessionValue.mopSignatures?.every?.(
                            (ms) => ms.isDefault
                        )
                    ) {
                        replaceSignature(data.signatures);
                    }

                    if (
                        sessionValue.mopContacts?.every?.((mc) => mc.isDefault)
                    ) {
                        replaceContact(data.contacts);
                    }
                }
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar("Couldn't retrieve job defaults.", {
                    variant: 'error',
                    autoHideDuration: 4000
                });
            });
    }, []); //eslint-disable-line

    const handleReturnToMOP = () => navigate('/mop/forms');

    const handleReset = () => {
        handleCloseReset();
        replaceMopEquipment([]);
        replaceMopStep([]);
        reset({
            author: displayName,
            backoutRequired: false,
            name: '',
            project: job?.vistaJobDescription,
            location: '',
            generalContractor: mopDefaults?.generalContractor ?? '',
            owner: mopDefaults?.owner ?? '',
            jobNumber: job?.vistaJobNumber,
            jobId: job?.id,
            generalDescription: '',
            systemsAffected: '',
            riskRating: null,
            tradesInvolved: '',
            proposedStart: null,
            proposedEnd: null,
            designatedStorageLocation: '',
            designatedStagingArea: '',
            equipmentModifications: '',
            locationPPERequirements: '',
            impactedSystems: '',
            safetyPrecautions: '',
            serviceProtection: '',
            contingencyPlan: '',
            pretaskReportCompleted: false,
            energizedPermitStatus: null,
            securityAccess: '',
            comments: '',
            mopContacts: mopDefaults?.contacts?.map?.((c) => ({
                ...c,
                isDefault: true
            })),
            mopSignatures:
                mopDefaults?.signatures?.map?.((s) => ({
                    ...s,
                    isDefault: true
                })) ?? []
        });
    };

    const handleCreate = (data) => {
        if (data.proposedStart > data.proposedEnd) {
            return setError('proposedEnd', {
                message: 'End date must be after start date.'
            });
        }

        let formData = new FormData();

        formData.append(
            'jsonData',
            JSON.stringify({
                ...data,
                jobId: job?.id,
                pretaskReportCompleted: !!data.pretaskReportCompleted,
                mopSteps: data.mopSteps?.map((ms, index) => ({
                    ...ms,
                    attachments: null,
                    sequence: index
                }))
            })
        );

        data.mopAttachments.forEach((attachment) =>
            formData.append(
                `file_${attachment.file.name}/${attachment.file.size}/${attachment.file.type}`,
                attachment.file
            )
        );

        data.mopSteps?.forEach((step, stepIndex) => {
            step.attachments?.forEach((attachment) =>
                formData.append(
                    `filestep-${stepIndex}-${attachment.file.name}-${attachment.file.size}-${attachment.file.type}`,
                    attachment.file
                )
            );
        });

        submitMOP({
            data: formData
        })
            .then((data) => {
                handleReset();
                enqueueSnackbar({
                    variant: 'success',
                    message: 'MOP Created',
                    autoHideDuration: 1500
                });
                if (data?.mopSignatures?.length === 0) navigate('/mop/forms');
            })
            .catch((err) => {
                console.error(err);
                if (
                    err?.response?.data?.detail ===
                    'End date must be after start date.'
                ) {
                    setError('proposedEnd', {
                        message: err?.response?.data?.detail
                    });
                } else {
                    enqueueSnackbar('Could not create MOP, please try again.', {
                        variant: 'error',
                        autoHideDuration: 4000
                    });
                }
            });
    };

    const handleCloseReset = () => setResetMop(false);

    const handleOpenReset = () => setResetMop(true);

    return (
        <div className={styles.container}>
            {!data ? (
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={handleSubmit(handleCreate)}
                        noValidate={true}
                        className={styles.form}
                    >
                        <Bookmark
                            panelContainerClass={styles.bookmarkPanelContainer}
                            overlayBreakpoint={1300}
                        >
                            <Bookmark.List>
                                <Bookmark.Section>
                                    <Bookmark.Option>
                                        General Information
                                    </Bookmark.Option>
                                    <Bookmark.Option>
                                        Attachments
                                    </Bookmark.Option>
                                    <Bookmark.Option>Steps</Bookmark.Option>
                                    <Bookmark.Option>
                                        Signatures
                                    </Bookmark.Option>
                                    <Bookmark.Option>Submit</Bookmark.Option>
                                </Bookmark.Section>
                                <Bookmark.Footer>
                                    <Bookmark.Link
                                        icon={faRefresh}
                                        handleClick={handleOpenReset}
                                    >
                                        Reset Form
                                    </Bookmark.Link>
                                    <Bookmark.Link
                                        icon={faArrowLeft}
                                        handleClick={handleReturnToMOP}
                                    >
                                        Back to MOPs
                                    </Bookmark.Link>
                                </Bookmark.Footer>
                            </Bookmark.List>
                            <Bookmark.Panel>
                                <MOPGeneralNew
                                    control={control}
                                    header="New MOP"
                                    appendContact={appendContact}
                                    removeContact={removeContact}
                                    updateContact={updateContact}
                                    appendEquipment={appendMopEquipment}
                                    removeEquipment={removeMopEquipment}
                                    updateEquipment={updateMopEquipment}
                                />
                            </Bookmark.Panel>
                            <Bookmark.Panel>
                                <MOPFileUpload
                                    append={appendMopAttachment}
                                    remove={removeMopAttachment}
                                    update={updateMopAttachment}
                                    fields={mopAttachments}
                                />
                            </Bookmark.Panel>
                            <Bookmark.Panel>
                                <MOPSteps
                                    fields={mopStepsField}
                                    control={control}
                                    name="mopSteps"
                                    append={appendMopStep}
                                    move={moveMopStep}
                                    remove={removeMopStep}
                                    update={updateMopStep}
                                />
                            </Bookmark.Panel>
                            <Bookmark.Panel>
                                <MOPSignatureEdit
                                    append={appendSignature}
                                    update={updateSignature}
                                    remove={removeSignature}
                                    fields={signaturesField}
                                />
                            </Bookmark.Panel>
                            <Bookmark.Panel>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '30px 0',
                                        width: 'calc(min(800px, 100%))',
                                        gap: '20px'
                                    }}
                                >
                                    {!isNullOrEmpty(errors) ? (
                                        <ErrorMessage error="Could not create the MOP, one or more required fields are not valid." />
                                    ) : (
                                        <p
                                            style={{
                                                height: '32px',
                                                width: '100%'
                                            }}
                                        />
                                    )}
                                    <Button
                                        label="Create MOP"
                                        icon={faSave}
                                        loading={loading}
                                        formAction="submit"
                                    />
                                </div>
                            </Bookmark.Panel>
                        </Bookmark>
                    </form>
                </FormProvider>
            ) : (
                <div className={styles.submittedContainer}>
                    <h3>MOP Created Successfully</h3>
                    <div>
                        <Button
                            variant="text"
                            label="Back to MOPs"
                            icon={faArrowLeft}
                            linkTo="/mop"
                        />
                    </div>
                    <div className={styles.notificationDisclaimer}>
                        Send email notifications to those that need to sign
                        below.
                    </div>
                    <MOPSignatureReview
                        mop={data}
                        fields={data?.mopSignatures}
                    />
                </div>
            )}
            {resetMop && (
                <ResetFormModal
                    handleClose={handleCloseReset}
                    handleReset={handleReset}
                />
            )}
        </div>
    );
};

export default NewMOP;
