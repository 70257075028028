import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../styles/general/input/ColorPicker.module.scss';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const colors = [
    '#000000',
    '#414141',
    '#777777',
    '#b4b4b4',
    '#ff7400',
    '#ff0000',
    '#01b500',
    '#00b8b3',
    '#006eb3',
    '#8341f0'
];

const ColorPicker = ({handleChange}) => {
    return (
        <div className={styles.container}>
            {colors.map((color) => (
                <div key={color} style={{ background: color }} className={styles.color} onClick={handleChange?.bind(this, color)}/>
            ))}
            <div className={styles.remove} onClick={handleChange?.bind(this, null)}>
                <FontAwesomeIcon icon={faTrashAlt}/>
            </div>
        </div>
    );
};

export default ColorPicker;
