import React from 'react';

import InputLabel from './InputLabel';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import {
    faCheckSquare,
    faMinusSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/Checkbox.module.scss';

const Checkbox = ({
    checked = false,
    handleChange = () => null,
    disabled = false,
    label,
    labelPosition = 'right',
    labelClick,
    id,
    neutral = false,
    style = {}
}) => {
    const _handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) handleChange?.();
    };

    return (
        <CheckboxContainer style={style}>
            <InputLabel
                label={label}
                position={labelPosition}
                style={{
                    label: {
                        color: 'var(--light-text-color)',
                        fontWeight: 500
                    },
                    input: {
                        display: 'flex'
                    },
                    ...style?.label
                }}
                tab
                labelClick={labelClick}
                handleSubmit={handleChange}
                for={id}
            >
                <NativeCheckbox
                    checked={checked}
                    handleChange={_handleChange}
                    id={id}
                />
                <CustomCheckbox
                    checked={checked}
                    neutral={neutral}
                    disabled={disabled}
                />
            </InputLabel>
        </CheckboxContainer>
    );
};

const CheckboxContainer = ({ children, style }) => (
    <div className={styles.checkboxContainer} style={style}>
        {children}
    </div>
);

const NativeCheckbox = ({ checked, handleChange, id }) => (
    <input
        type="checkbox"
        defaultChecked={checked ? checked : false}
        className={styles.nativeCheckbox}
        onClick={handleChange}
        tabIndex={-1}
        id={id}
    />
);

const CustomCheckbox = ({ checked, neutral, disabled }) => (
    <FontAwesomeIcon
        icon={checked ? faCheckSquare : neutral ? faMinusSquare : faSquare}
        className={[
            styles.customCheckbox,
            checked ? styles['checked'] : neutral ? styles['neutral'] : null,
            disabled ? styles['disabled'] : null
        ].join(' ')}
    />
);

Checkbox.propTypes = {
    checked: PropTypes.bool,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    neutral: PropTypes.bool,
    style: PropTypes.object
};

export default Checkbox;
