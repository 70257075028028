import FileUpload from '../../general/input/FileUpload';

const MOPFileUpload = ({
    append,
    remove,
    fields = [],
    ...props
}) => {

    const handleRemove = (id) => {
        remove?.(fields.findIndex(field => field.id === id), fields.find(field => field.id === id));
    };

    const handleUpload = (attachments) => {
        append?.(attachments);
    };

    return (
        <FileUpload
            files={fields}
            handleRemove={handleRemove}
            handleUpload={handleUpload}
            downloadSingle
            {...props}
        />
    );
};

export default MOPFileUpload;
