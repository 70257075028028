import React from 'react';

import Checkbox from './Checkbox';
import InputLabel from './InputLabel';
import PropTypes from 'prop-types';

import styles from '../../../styles/general/input/CheckboxGroup.module.scss';

const CheckboxGroup = ({
    options = [],
    handleChange: _handleChange = () => null,
    selected,
    multiselect = false,
    required = false,
    orientation = 'row',
    style = {},
    readOnly
}) => {
    const handleChange = (key) => {
        if (multiselect) {
            if (Array.isArray(selected)) {
                _handleChange(
                    selected.includes(key)
                        ? selected.length === 1 && required
                            ? selected
                            : selected.filter((item) => item !== key)
                        : [...selected, key]
                );
            } else {
                _handleChange(
                    selected === key
                        ? required
                            ? [selected]
                            : []
                        : selected
                        ? [selected, key]
                        : [key]
                );
            }
        } else {
            _handleChange(
                selected === key ? (required ? selected : null) : key
            );
        }
    };

    return (
        <div
            className={[styles.container, styles[orientation]].join(' ')}
            style={style?.container}
        >
            {options.map((option) => (
                <InputLabel
                    key={option?.key}
                    label={option.value}
                    position="right"
                    style={{
                        label: {
                            color: 'var(--light-text-color)',
                            fontWeight: 400
                        },
                        ...style?.input
                    }}
                    //tab={true}
                    handleSubmit={handleChange.bind(this, option?.key)}
                >
                    <Checkbox
                        checked={
                            multiselect
                                ? selected?.includes?.(option?.key)
                                : selected === option?.key
                        }
                        handleChange={handleChange.bind(this, option?.key)}
                        disabled={readOnly}
                    />
                </InputLabel>
            ))}
        </div>
    );
};

CheckboxGroup.propTypes = {
    handleChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            value: PropTypes.string
        })
    ),
    orientation: PropTypes.oneOf(['row', 'column']),
    multiselect: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ]),
    readOnly: PropTypes.bool
};

export default CheckboxGroup;
